import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';
type AOA = any[][];

@Component({
	selector: 'app-projelertamamlanan',
	templateUrl: './projelertamamlanan.component.html',
	styleUrls: ['./projelertamamlanan.component.scss']
})
export class ProjelerTamamlananListesiComponent implements OnInit {
	Yil = '';
	rows = [];
	temp = [];
	loading: boolean = false;

	data: AOA = [];
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: string = 'ProjelerTamamlananListesi.xlsx';
	@ViewChild(DatatableComponent) table: DatatableComponent;

	constructor(
		private modalService: NgbModal,
		private toastr: ToastrService,
		private http: HttpClient,
		private globals: Globals,
		private activatedRoute: ActivatedRoute,
	) {
		// this.temp = [...data];
		// this.rows = data;
		this.activatedRoute.params
			.subscribe(params => {
				// console.log(params.ProjeId);
				this.Yil = params.Yil.toString();
			});
		this.DataYukle();
	}

	ngOnInit() {

	}

	DataYukle() {
		let params = new HttpParams();
		params = params.append('Durum', '1');
		params = params.append('Yil', this.Yil);
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/AdminProjeler', { params, headers }).subscribe(
			(response) => {
				// console.log(response);
				let TempJson = JSON.parse(JSON.stringify(response));
				TempJson.forEach(element => {
					element['Id'] = element['Id'].toString();
					let ReklamverenYetkililer = '';
					for (var i = 0; i < element['ReklamverenYetkililer'].length; i++) {
						if (i > 0) {
							ReklamverenYetkililer = ReklamverenYetkililer + ', ';
						}
						ReklamverenYetkililer = ReklamverenYetkililer + element['ReklamverenYetkililer'][i]['AdSoyad'] + ' ( ' + element['ReklamverenYetkililer'][i]['Eposta'] + ' )';
					}
					element.ReklamverenYetkililer = ReklamverenYetkililer;
					let BasvuranAjansYetkililer = '';
					for (var i = 0; i < element['BasvuranAjansYetkililer'].length; i++) {
						if (i > 0) {
							BasvuranAjansYetkililer = BasvuranAjansYetkililer + ', ';
						}
						BasvuranAjansYetkililer = BasvuranAjansYetkililer + element['BasvuranAjansYetkililer'][i]['AdSoyad'] + ' ( ' + element['BasvuranAjansYetkililer'][i]['Eposta'] + ' )';
					}
					element.BasvuranAjansYetkililer = BasvuranAjansYetkililer;
					element.KatkidaBulunanAjanslarFormated = '';
					for (var i = 0; i < element['KatkidaBulunanAjanslar'].length; i++) {
						if (i > 0) {
							element.KatkidaBulunanAjanslarFormated = element.KatkidaBulunanAjanslarFormated + ', ';
						}
						element.KatkidaBulunanAjanslarFormated = element.KatkidaBulunanAjanslarFormated + element['KatkidaBulunanAjanslar'][i]['Ajans'];
					}
					if (element["GuncellemeTarihi"] == null) {
						element["GuncellemeTarihi"] = element["Tarih"];
					}
					this.rows.push(element);
					this.temp.push(element);
				});
				// console.log(this.rows);
				// console.log(this.temp);
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	updateIdFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.Id.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	updateKampanyaAdiFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.KampanyaAdi.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	updateTarihBasFilter(event) {
		// console.log("bas");
		const val = event.target.value;
		// console.log(event)
		// console.log(val)
		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.GuncellemeTarihi >= val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}
	updateTarihBitFilter(event) {
		// console.log("bit");
		const val = event.target.value;
		// console.log(event)
		// console.log(val)
		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.GuncellemeTarihi <= val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}
	updateEbookIzinFilter(event) {
		const val = event.target.value;
		let gecici: any;

		// filter our data
		if (val == -1) {
			gecici = this.temp;
		}
		else {
			gecici = this.temp.filter(function (d) {
				return d.EbookIzin == val;
			});
		}

		// update the rows
		this.rows = gecici;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	updateFinaleKalanlarFilter(event) {
		const val = event.target.value;
		let gecici: any;

		// filter our data
		if (val == -1) {
			gecici = this.temp;
		}
		else {
			gecici = this.temp.filter(function (d) {
				return d.FinaleKalanlar == val;
			});
		}

		// update the rows
		this.rows = gecici;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}
	updateOdulFilter(event) {
		const val = event.target.value;
		let gecici: any;

		// filter our data
		if (val == -1) {
			gecici = this.temp;
		}
		else {
			gecici = this.temp.filter(function (d) {
				return d.Odul == val;
			});
		}

		// update the rows
		this.rows = gecici;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	onSort(event) {
		// event was triggered, start sort sequence
		// console.log('Sort Event', event);
		this.loading = true;
		// emulate a server request with a timeout
		// setTimeout(() => {
		const rows = [...this.rows];
		// this is only for demo purposes, normally
		// your server would return the result for
		// you and you would just set the rows prop
		const sort = event.sorts[0];
		// console.log(rows);
		// console.log(sort);
		rows.sort((a, b) => {
			return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
		});

		this.rows = rows;
		this.loading = false;
		// }, 1000);
	}

	private getDismissReason(reason: any): string {
		// console.log(reason);

		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return "with: ${reason}";
		}
	}

	ExcelExport() {
		// console.log(this.PageData);
		// console.log(this.Excel)
		this.data = []
		var Heading = [];
		Heading.push("Id")
		Heading.push("Marka Adı");
		Heading.push("Marka İli");
		Heading.push("Marka Ülkesi");
		Heading.push("Kampanya Adı");
		Heading.push("Kategori");
		Heading.push("Alt Kategori");
		Heading.push("Reklamveren Adı");
		Heading.push("Reklamveren Adres");
		Heading.push("Reklamveren İli");
		Heading.push("Reklamveren Ülkesi");
		Heading.push("Reklamveren Web Sitesi");
		Heading.push("Reklamveren Holding");
		Heading.push("Reklamveren Yetkililer");
		Heading.push("BaşvuranAnaAjans Adı");
		Heading.push("BaşvuranAnaAjans Adres");
		Heading.push("BaşvuranAnaAjans İli");
		Heading.push("BaşvuranAnaAjans Ülkesi");
		Heading.push("BaşvuranAnaAjans Web Sitesi");
		Heading.push("BaşvuranAnaAjans Network");
		Heading.push("BaşvuranAnaAjans Holding");
		Heading.push("BaşvuranAnaAjans Yetkililer");
		Heading.push("Katkıda Bulunan Ajans 1");
		Heading.push("Ajans 1 Adı");
		Heading.push("Ajans 1 İli");
		Heading.push("Ajans 1 Ülkesi");
		Heading.push("Ajans 1 Web Sitesi");
		Heading.push("Ajans 1 Network");
		Heading.push("Ajans 1 Holding");
		Heading.push("Katkıda Bulunan Ajans 2");
		Heading.push("Ajans 2 Adı");
		Heading.push("Ajans 2 İli");
		Heading.push("Ajans 2 Ülkesi");
		Heading.push("Ajans 2 Web Sitesi");
		Heading.push("Ajans 2 Network");
		Heading.push("Ajans 2 Holding");
		Heading.push("Katkıda Bulunan Ajans 3");
		Heading.push("Ajans 3 Adı");
		Heading.push("Ajans 3 İli");
		Heading.push("Ajans 3 Ülkesi");
		Heading.push("Ajans 3 Web Sitesi");
		Heading.push("Ajans 3 Network");
		Heading.push("Ajans 3 Holding");
		Heading.push("Katkıda Bulunan Ajans 4");
		Heading.push("Ajans 4 Adı");
		Heading.push("Ajans 4 İli");
		Heading.push("Ajans 4 Ülkesi");
		Heading.push("Ajans 4 Web Sitesi");
		Heading.push("Ajans 4 Network");
		Heading.push("Ajans 4 Holding");
		Heading.push("FormuDolduran Adı");
		Heading.push("FormuDolduran Gorevi");
		Heading.push("FormuDolduran Tel");
		Heading.push("FormuDolduran Eposta");
		Heading.push("Tarih");
		Heading.push("Güncelleme Tarihi");
		Heading.push("Sayfa Sayısı");
		Heading.push("Price");
		Heading.push("Dosya 1");
		Heading.push("Dosya 2");
		Heading.push("Dosya 3");
		Heading.push("Dosya 4");
		Heading.push("Dosya 5");
		Heading.push("Dosya 6");
		this.data.push(Heading);

		this.rows.forEach(element => {
			let temp = [];
			temp.push(element["Id"]);
			temp.push(element["MarkaAdi"]);
			temp.push(element["MarkaIl"]);
			temp.push(element["MarkaUlke"]);
			temp.push(element["KampanyaAdi"]);
			temp.push(element["Kategori"]);
			temp.push(element["SubKategori"]);
			temp.push(element["ReklamverenAdi"]);
			temp.push(element["ReklamverenAdres"]);
			temp.push(element["ReklamverenIl"]);
			temp.push(element["ReklamverenUlke"]);
			temp.push(this.HTTPControl(element["ReklamverenWeb"]));
			temp.push(element["ReklamverenHolding"]);
			temp.push(element["ReklamverenYetkililer"]);
			temp.push(element["BasvuranAjansAdi"]);
			temp.push(element["BasvuranAjansAdres"]);
			temp.push(element["BasvuranAjansIl"]);
			temp.push(element["BasvuranAjansUlke"]);
			temp.push(this.HTTPControl(element["BasvuranAjansWeb"]));
			temp.push(element["BasvuranAjansNetwork"]);
			temp.push(element["BasvuranAjansHolding"]);
			temp.push(element["BasvuranAjansYetkililer"]);
			temp.push('');
			if (element['KatkidaBulunanAjanslar'].length > 0) {
				temp.push(element['KatkidaBulunanAjanslar'][0]["Ajans"]);
				temp.push(element['KatkidaBulunanAjanslar'][0]["Il"]);
				temp.push(element['KatkidaBulunanAjanslar'][0]["Ulke"]);
				temp.push(this.HTTPControl(element['KatkidaBulunanAjanslar'][0]["Web"]));
				temp.push(element['KatkidaBulunanAjanslar'][0]["Network"]);
				temp.push(element['KatkidaBulunanAjanslar'][0]["Holding"]);
			}
			else {
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
			}
			temp.push('');
			if (element['KatkidaBulunanAjanslar'].length > 1) {
				temp.push(element['KatkidaBulunanAjanslar'][1]["Ajans"]);
				temp.push(element['KatkidaBulunanAjanslar'][1]["Il"]);
				temp.push(element['KatkidaBulunanAjanslar'][1]["Ulke"]);
				temp.push(this.HTTPControl(element['KatkidaBulunanAjanslar'][1]["Web"]));
				temp.push(element['KatkidaBulunanAjanslar'][1]["Network"]);
				temp.push(element['KatkidaBulunanAjanslar'][1]["Holding"]);
			}
			else {
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
			}
			temp.push('');
			if (element['KatkidaBulunanAjanslar'].length > 2) {
				temp.push(element['KatkidaBulunanAjanslar'][2]["Ajans"]);
				temp.push(element['KatkidaBulunanAjanslar'][2]["Il"]);
				temp.push(element['KatkidaBulunanAjanslar'][2]["Ulke"]);
				temp.push(this.HTTPControl(element['KatkidaBulunanAjanslar'][2]["Web"]));
				temp.push(element['KatkidaBulunanAjanslar'][2]["Network"]);
				temp.push(element['KatkidaBulunanAjanslar'][2]["Holding"]);
			}
			else {
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
			}
			temp.push('');
			if (element['KatkidaBulunanAjanslar'].length > 3) {
				temp.push(element['KatkidaBulunanAjanslar'][3]["Ajans"]);
				temp.push(element['KatkidaBulunanAjanslar'][3]["Il"]);
				temp.push(element['KatkidaBulunanAjanslar'][3]["Ulke"]);
				temp.push(this.HTTPControl(element['KatkidaBulunanAjanslar'][3]["Web"]));
				temp.push(element['KatkidaBulunanAjanslar'][3]["Network"]);
				temp.push(element['KatkidaBulunanAjanslar'][3]["Holding"]);
			}
			else {
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
				temp.push('');
			}
			temp.push(element["FormuDolduranAdi"]);
			temp.push(element["FormuDolduranGorevi"]);
			temp.push(element["FormuDolduranTelefon"]);
			temp.push(element["FormuDolduranEposta"]);
			temp.push(element["Tarih"].toString());
			temp.push(element["GuncellemeTarihi"].toString());
			temp.push(element["Adim9SayfaSayisi"].toString());
			temp.push(parseInt(element["Price"]));
			if (element['Dosyalar'].length > 0) {
				temp.push(this.HTTPControl(element['Dosyalar'][0]['DosyaLink']));
			}
			else {
				temp.push('');
			}
			if (element['Dosyalar'].length > 1) {
				temp.push(this.HTTPControl(element['Dosyalar'][1]['DosyaLink']));
			}
			else {
				temp.push('');
			}
			if (element['Dosyalar'].length > 2) {
				temp.push(this.HTTPControl(element['Dosyalar'][2]['DosyaLink']));
			}
			else {
				temp.push('');
			}
			if (element['Dosyalar'].length > 3) {
				temp.push(this.HTTPControl(element['Dosyalar'][3]['DosyaLink']));
			}
			else {
				temp.push('');
			}
			if (element['Dosyalar'].length > 4) {
				temp.push(this.HTTPControl(element['Dosyalar'][4]['DosyaLink']));
			}
			else {
				temp.push('');
			}
			if (element['Dosyalar'].length > 5) {
				temp.push(this.HTTPControl(element['Dosyalar'][5]['DosyaLink']));
			}
			else {
				temp.push('');
			}
			this.data.push(temp);
		});
		// console.log(this.data)
		// return
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, this.fileName);
	}

	TarihFormatla(Tarih) {
		var options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}

	HTTPControl(link: string) {
		if (link.trim().length) {
			if (!link.includes("http")) {
				link = 'https://' + link;
			}
		}
		return link;
	}

}
