import { Component, OnInit } from '@angular/core';
import { MessageServiceService } from './../services/message-service.service';

@Component({
	selector: 'app-logout',
	templateUrl: './logout.component.html',
	styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

	constructor(private messageService: MessageServiceService) {
		this.messageService.sendMessage("logout");
	}

	ngOnInit() {
	}

}
