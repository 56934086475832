import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
	selector: 'app-kullaniciedit',
	templateUrl: './kullaniciedit.component.html',
	styleUrls: ['./kullaniciedit.component.scss']
})
export class KullanicieditComponent implements OnInit {
	KullaniciId = "";
	PageData: Object = [];
	yuklendi = false;
	data: AOA = [];
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: string = 'Kullanicilar.xlsx';
	Excel = {
		AdSoyad: true,
		Eposta: true,
		FirmaAdi: true,
		Gorevi: true,
		Telefon: true,
		CreatedAt: true,
		MailApproved: true,
		IsAdmin: true
	};

	constructor(
		private toastr: ToastrService,
		private activatedRoute: ActivatedRoute,
		private modalService: NgbModal,
		private http: HttpClient,
		private globals: Globals,
	) {
		this.activatedRoute.params
			.subscribe(params => {
				this.KullaniciId = params.KullaniciId;
				this.fileName = this.KullaniciId + ".xlsx";
			});
	}

	ngOnInit() {
		this.DataYukle();
	}

	DataYukle() {
		let params = new HttpParams();
		params = params.append('KullaniciId', this.KullaniciId);
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/AdminKullanici', { params, headers }).subscribe(
			(response) => {
				if(response["success"] == true)
				{
					this.PageData = response;
					this.PageData['Id'] = this.PageData['Id'].toString();
				}
				else
				{
					this.toastr. error('Kullanıcı bulunamadı');
				}
				this.yuklendi = true;
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr. error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	Kaydet() {
		let temp = JSON.parse(JSON.stringify(this.PageData))
		delete temp['RegisterTarih'];
		delete temp['GuncellemeTarih'];
		// console.log(temp)
		// return
		if (temp['AdSoyad'].length == 0 || temp['AdSoyad'] == '') {
			this.toastr. error('Ad Soyad boş olamaz.');
			return;
		}
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/AdminKullanici', temp, { params, headers }).subscribe(
			(response) => {
				this.toastr. success('Kaydedildi.');
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr. error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	OpenExcelSelector(content) {
		this.modalService.open(content, { size: 'lg' }).result.then((result) => {
			// console.log(type);
			// this.closeResult = "Closed with: ${result}";

			// console.log("kpamdı");
			// console.log(result);
		}, (reason) => {
			// this.DosyaAdi = "Choose file";
			// this.closeResult = "Dismissed ${this.getDismissReason(reason)}";
			// console.log("kpamdı2");
			console.log("Dismissed ${this.getDismissReason(reason)}");
		});
	}

	private getDismissReason(reason: any): string {
		// console.log(reason);
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return "with: ${reason}";
		}
	}

	ExcelExport() {
		// console.log(this.PageData);
		var Heading = [];
		if (this.Excel.AdSoyad == true) {
			Heading.push("Ad Soyad");
		}
		if (this.Excel.Eposta == true) {
			Heading.push("E-Posta");
		}
		if (this.Excel.FirmaAdi == true) {
			Heading.push("Firma Adı");
		}
		if (this.Excel.Gorevi == true) {
			Heading.push("Görevi");
		}
		if (this.Excel.Telefon == true) {
			Heading.push("Telefon");
		}
		if (this.Excel.CreatedAt == true) {
			Heading.push("Üyelik Tarihi");
		}
		if (this.Excel.MailApproved == true) {
			Heading.push("Mail Onay");
		}
		if (this.Excel.IsAdmin == true) {
			Heading.push("IsAdmin");
		}
		this.data.push(Heading);



		var temp = [];
		if (this.Excel.AdSoyad == true) {
			temp.push(this.PageData["AdSoyad"]);
		}
		if (this.Excel.Eposta == true) {
			temp.push(this.PageData["Eposta"]);
		}
		if (this.Excel.FirmaAdi == true) {
			temp.push(this.PageData["FirmaAdi"]);
		}
		if (this.Excel.Gorevi == true) {
			temp.push(this.PageData["Gorev"]);
		}
		if (this.Excel.Telefon == true) {
			temp.push(this.PageData["Telefon"]);
		}
		if (this.Excel.CreatedAt == true) {
			temp.push(this.PageData["RegisterTarih"]);
		}
		if (this.Excel.MailApproved == true) {
			if (this.PageData["MailApproved"] == true) {
				temp.push("Evet");
			}
			else {
				temp.push("Hayır");
			}
		}
		if (this.Excel.IsAdmin == true) {
			if (this.PageData["IsAdmin"] == true) {
				temp.push("Evet");
			}
			else {
				temp.push("Hayır");
			}
		}
		this.data.push(temp);


		// console.log(this.data)
		// return

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, this.fileName);
	}

	TarihFormatla(Tarih) {
		var options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}
}
