import { Component, OnInit } from '@angular/core';
import { Globals } from '../providers/globals/globals';
import { ToastrService } from 'ngx-toastr';;

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
	PageData = [];
	yuklendi = false;

	constructor(
		public globals: Globals,
		private toastr: ToastrService,
	) {

	}

	ngOnInit() {
		this.DataYukle();
	}



	DataYukle() {
		// let that = this;
		// this.db.collection("Yonetim").ref.get().then((querySnapshot) => {
		// 	querySnapshot.forEach((doc) => {
		// 		// console.log(`${doc.id} => ${doc.data()}`);
		// 		var data = doc.data();
		// 		data["Sene"] = doc.id;
		// 		that.yuklendi = true;
		// 		that.PageData.push(data);
		// 	});
		// 	that.globals.Yonetim = that.PageData;
		// });
	}

	AktifChange(i) {
		console.log(this.globals.Yonetim[i].Aktif);
		console.log(this.globals.Yonetim)
		if(this.globals.Yonetim[i].Aktif == false)
		{
			this.globals.Yonetim.forEach(element => {
				element.Aktif = false;
			});
			this.globals.Yonetim[i].Aktif = true;
		}
	}

	JuriGirisChange(i) {
		if(this.globals.Yonetim[i].JuriGiris == false)
		{
			this.globals.Yonetim.forEach(element => {
				element.JuriGiris = false;
			});
			this.globals.Yonetim[i].JuriGiris = true;
		}
	}

	SiteyeGirisChange(i) {
		console.log(this.globals.Yonetim[i].SiteyeGiris);
		console.log(this.globals.Yonetim)
		// return;
		if(this.globals.Yonetim[i].SiteyeGiris == false)
		{
			this.globals.Yonetim.forEach(element => {
				element.SiteyeGiris = false;
			});
			this.globals.Yonetim[i].SiteyeGiris = true;
		}
		
	}

	Kaydet()
	{
		// this.globals.Yonetim.forEach(element => {
		// 	this.db.collection("Yonetim").doc(element.Sene).set({Aktif: element.Aktif, SiteyeGiris: element.SiteyeGiris, JuriGiris: element.JuriGiris}, {merge: true});
		// });
		this.toastr. success("Kaydedildi");
	}

}
