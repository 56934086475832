import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../providers/globals/globals';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
	selector: 'app-juri',
	templateUrl: './jurilistesi.component.html',
	styleUrls: ['./jurilistesi.component.scss']
})
export class JuriListesiComponent implements OnInit {
	rows = [];
	temp = [];
	loading: boolean = false;
	data: AOA = [];
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: string = 'Juriler.xlsx';
	Excel = {
		AdSoyad: true,
		Eposta: true,
		Telefon: true,
		Sifre: true,
	};

	@ViewChild(DatatableComponent) table: DatatableComponent;

	constructor(
		public globals: Globals,
		private modalService: NgbModal,
		private toastr: ToastrService,
	) {
		// this.temp = [...data];
		// this.rows = data;
		this.DataYukle();
	}

	ngOnInit() {

	}

	DataYukle() {
		// let that = this;
		// this.db.collection("Juriler").ref.get().then(function (querySnapshot) {
		// 	querySnapshot.forEach(function(doc) {
		// 		var temp = doc.data();
		// 		temp["Id"] = doc.id;
		// 		that.rows.push(temp);
		// 		that.temp.push(temp);
		// 	});
		// }).catch(function (error) {
		// 	console.log("Error getting document:", error);
		// 	that.toastr.typeError("Bir hata oluştu, Lütfen tekrar deneyiniz");
		// });
	}

	updateAdSoyadFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.AdSoyad.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	onSort(event) {
		this.loading = true;
		const rows = [...this.rows];
		const sort = event.sorts[0];
		rows.sort((a, b) => {
			return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
		});

		this.rows = rows;
		this.loading = false;
	}

	OpenExcelSelector(content) {

		this.modalService.open(content, { size: 'lg' }).result.then((result) => {
			// console.log(type);
			// this.closeResult = "Closed with: ${result}";

			// console.log("kpamdı");
			// console.log(result);
		}, (reason) => {
			// this.DosyaAdi = "Choose file";
			// this.closeResult = "Dismissed ${this.getDismissReason(reason)}";
			// console.log("kpamdı2");
			console.log("Dismissed ${this.getDismissReason(reason)}");
		});
	}

	private getDismissReason(reason: any): string {
		// console.log(reason);

		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return "with: ${reason}";
		}
	}

	ExcelExport() {
		// console.log(this.PageData);
		var Heading = [];
		if (this.Excel.AdSoyad == true) {
			Heading.push("Ad Soyad");
		}
		if (this.Excel.Eposta == true) {
			Heading.push("E-Posta");
		}
		if (this.Excel.Telefon == true) {
			Heading.push("Telefon");
		}
		if (this.Excel.Sifre == true) {
			Heading.push("Şifre");
		}
		this.data.push(Heading);


		this.rows.forEach(element => {
			var temp = [];
			if (this.Excel.AdSoyad == true) {
				temp.push(element["AdSoyad"]);
			}
			if (this.Excel.Eposta == true) {
				temp.push(element["Eposta"]);
			}
			if (this.Excel.Telefon == true) {
				temp.push(element["Telefon"]);
			}
			if (this.Excel.Sifre == true) {
				temp.push(element["Sifre"]);
			}
			this.data.push(temp);
		});

		// console.log(this.data)
		// return

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, this.fileName);
	}

	TarihFormatla(Tarih) {
		var options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}
}
