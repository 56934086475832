import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
type AOA = any[][];

@Component({
	selector: 'app-projeedit',
	templateUrl: './projeedit.component.html',
	styleUrls: ['./projeedit.component.scss']
})
export class ProjeEditComponent implements OnInit {
	data: AOA = [];
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: string = 'Proje.xlsx';

	ProjeId = "";
	PageData = {};
	Dosyalar = []
	IzinBelgeleri = []
	yuklendi = false;

	ReklamverenYetkililer = "";
	BasvuranAjansYetkililer = "";
	KatkidaBulunanAjanslar = "";
	PDFHtml = '';
	PDFHtml2 = '';
	PDFHtml3 = '';
	isLoading = false;

	constructor(
		private toastr: ToastrService,
		private activatedRoute: ActivatedRoute,
		private modalService: NgbModal,
		private http: HttpClient,
		private globals: Globals,
	) {
		this.activatedRoute.params
			.subscribe(params => {
				// console.log(params.ProjeId);
				this.ProjeId = params.ProjeId.toString();
				this.fileName = this.ProjeId + ".xlsx";
			});
	}

	ngOnInit() {
		this.DataYukle();
	}

	DataYukle() {
		// console.log(this.ProjeId)
		let params = new HttpParams();
		params = params.append('ProjeId', this.ProjeId);
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/AdminProje', { params, headers }).subscribe(
			(response) => {
				this.PageData = response;
				this.PageData['Id'] = this.PageData['Id'].toString();
				this.ReklamverenYetkililer = '';
				for (var i = 0; i < this.PageData['ReklamverenYetkililer'].length; i++) {
					if (i > 0) {
						this.ReklamverenYetkililer = this.ReklamverenYetkililer + ', ';
					}
					this.ReklamverenYetkililer = this.ReklamverenYetkililer + this.PageData['ReklamverenYetkililer'][i]['AdSoyad'] + ' ( ' + this.PageData['ReklamverenYetkililer'][i]['Eposta'] + ' )';
				}

				this.BasvuranAjansYetkililer = '';
				for (var i = 0; i < this.PageData['BasvuranAjansYetkililer'].length; i++) {
					if (i > 0) {
						this.BasvuranAjansYetkililer = this.BasvuranAjansYetkililer + ', ';
					}
					this.BasvuranAjansYetkililer = this.BasvuranAjansYetkililer + this.PageData['BasvuranAjansYetkililer'][i]['AdSoyad'] + ' ( ' + this.PageData['BasvuranAjansYetkililer'][i]['Eposta'] + ' )';
				}

				this.KatkidaBulunanAjanslar = '';
				for (var i = 0; i < this.PageData['KatkidaBulunanAjanslar'].length; i++) {
					if (i > 0) {
						this.KatkidaBulunanAjanslar = this.KatkidaBulunanAjanslar + ', ';
					}
					this.KatkidaBulunanAjanslar = this.KatkidaBulunanAjanslar + this.PageData['KatkidaBulunanAjanslar'][i]['Ajans'];
				}

				this.PageData["TamamlanmaOrani"] = 0;
				//this.PageData["RegisterTarih"] = that.TarihFormatla(this.PageData["RegisterTarihi"].toDate());
				if (this.PageData['Adim1Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim2Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim3Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim4Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim5Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim6Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim7Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim8Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim9Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				if (this.PageData['Adim10Durum'] == true) {
					this.PageData["TamamlanmaOrani"]++;
				}
				this.PageData["TamamlanmaOrani"] = this.PageData["TamamlanmaOrani"].toString();
				this.Dosyalar = this.PageData['Adim8'];
				this.IzinBelgeleri = this.PageData['Adim10'];
				if (this.PageData["GuncellemeTarihi"] == null) {
					this.PageData["GuncellemeTarihi"] = this.PageData["Tarih"];
				}

				this.yuklendi = true;
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	Kaydet() {
		// console.log(this.PageData);
		// return;

		let Deleted = false;
		// console.log(this.PageData['Deleted'])
		if (this.PageData['Deleted'] == "true") {
			Deleted = true;
		}

		const temp = {
			Id: this.ProjeId,
			Deleted: this.PageData['Deleted'],
			EBook_Onay: this.PageData["EBook_Onay"],
			IpsosIzin: 1,
			EbookIzin: this.PageData["EbookIzin"],
			GenelIzin: this.PageData["GenelIzin"],
			MarkaAdi: this.PageData["MarkaAdi"],
			KampanyaAdi: this.PageData["KampanyaAdi"],
			BasvuranAjansAdi: this.PageData["BasvuranAjansAdi"]
		}
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/AdminProje', temp, { params, headers }).subscribe(
			(response) => {
				this.toastr.success('Kaydedildi.');
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	private getDismissReason(reason: any): string {
		// console.log(reason);

		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return "with: ${reason}";
		}
	}

	ExcelExport() {
		// console.log(this.PageData);
		// console.log(this.Excel)
		this.data = []
		var Heading = [];
		Heading.push("Id")
		Heading.push("Marka Adı");
		Heading.push("Marka İli");
		Heading.push("Marka Ülkesi");
		Heading.push("Kampanya Adı");
		Heading.push("Kategori");
		Heading.push("Alt Kategori");
		Heading.push("Reklamveren Adı");
		Heading.push("Reklamveren Adres");
		Heading.push("Reklamveren İli");
		Heading.push("Reklamveren Ülkesi");
		Heading.push("Reklamveren Web Sitesi");
		Heading.push("Reklamveren Holding");
		Heading.push("Reklamveren Yetkililer");
		Heading.push("BaşvuranAnaAjans Adı");
		Heading.push("BaşvuranAnaAjans Adres");
		Heading.push("BaşvuranAnaAjans İli");
		Heading.push("BaşvuranAnaAjans Ülkesi");
		Heading.push("BaşvuranAnaAjans Web Sitesi");
		Heading.push("BaşvuranAnaAjans Network");
		Heading.push("BaşvuranAnaAjans Holding");
		Heading.push("BaşvuranAnaAjans Yetkililer");
		Heading.push("Katkıda Bulunan Ajans 1");
		Heading.push("Ajans 1 Adı");
		Heading.push("Ajans 1 İli");
		Heading.push("Ajans 1 Ülkesi");
		Heading.push("Ajans 1 Web Sitesi");
		Heading.push("Ajans 1 Network");
		Heading.push("Ajans 1 Holding");
		Heading.push("Katkıda Bulunan Ajans 2");
		Heading.push("Ajans 2 Adı");
		Heading.push("Ajans 2 İli");
		Heading.push("Ajans 2 Ülkesi");
		Heading.push("Ajans 2 Web Sitesi");
		Heading.push("Ajans 2 Network");
		Heading.push("Ajans 2 Holding");
		Heading.push("Katkıda Bulunan Ajans 3");
		Heading.push("Ajans 3 Adı");
		Heading.push("Ajans 3 İli");
		Heading.push("Ajans 3 Ülkesi");
		Heading.push("Ajans 3 Web Sitesi");
		Heading.push("Ajans 3 Network");
		Heading.push("Ajans 3 Holding");
		Heading.push("Katkıda Bulunan Ajans 4");
		Heading.push("Ajans 4 Adı");
		Heading.push("Ajans 4 İli");
		Heading.push("Ajans 4 Ülkesi");
		Heading.push("Ajans 4 Web Sitesi");
		Heading.push("Ajans 4 Network");
		Heading.push("Ajans 4 Holding");
		Heading.push("FormuDolduran Adı");
		Heading.push("FormuDolduran Gorevi");
		Heading.push("FormuDolduran Tel");
		Heading.push("FormuDolduran Eposta");
		Heading.push("Tarih");
		Heading.push("Güncelleme Tarihi");
		Heading.push("Sayfa Sayısı");
		Heading.push("Tamamlanma Oranı");
		Heading.push("Price");
		Heading.push("Dosya 1");
		Heading.push("Dosya 2");
		Heading.push("Dosya 3");
		Heading.push("Dosya 4");
		Heading.push("Dosya 5");
		Heading.push("Dosya 6");
		this.data.push(Heading);

		let temp = [];
		temp.push(this.PageData["Id"]);
		temp.push(this.PageData["MarkaAdi"]);
		temp.push(this.PageData["MarkaIl"]);
		temp.push(this.PageData["MarkaUlke"]);
		temp.push(this.PageData["KampanyaAdi"]);
		temp.push(this.PageData["Kategori"]);
		temp.push(this.PageData["SubKategori"]);
		temp.push(this.PageData["ReklamverenAdi"]);
		temp.push(this.PageData["ReklamverenAdres"]);
		temp.push(this.PageData["ReklamverenIl"]);
		temp.push(this.PageData["ReklamverenUlke"]);
		temp.push(this.HTTPControl(this.PageData["ReklamverenWeb"]));
		temp.push(this.PageData["ReklamverenHolding"]);
		temp.push(this.ReklamverenYetkililer);
		temp.push(this.PageData["BasvuranAjansAdi"]);
		temp.push(this.PageData["BasvuranAjansAdres"]);
		temp.push(this.PageData["BasvuranAjansIl"]);
		temp.push(this.PageData["BasvuranAjansUlke"]);
		temp.push(this.HTTPControl(this.PageData["BasvuranAjansWeb"]));
		temp.push(this.PageData["BasvuranAjansNetwork"]);
		temp.push(this.PageData["BasvuranAjansHolding"]);
		temp.push(this.BasvuranAjansYetkililer);
		temp.push('');
		if (this.PageData['KatkidaBulunanAjanslar'].length > 0) {
			temp.push(this.PageData['KatkidaBulunanAjanslar'][0]["Ajans"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][0]["Il"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][0]["Ulke"]);
			temp.push(this.HTTPControl(this.PageData['KatkidaBulunanAjanslar'][0]["Web"]));
			temp.push(this.PageData['KatkidaBulunanAjanslar'][0]["Network"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][0]["Holding"]);
		}
		else {
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
		}
		temp.push('');
		if (this.PageData['KatkidaBulunanAjanslar'].length > 1) {
			temp.push(this.PageData['KatkidaBulunanAjanslar'][1]["Ajans"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][1]["Il"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][1]["Ulke"]);
			temp.push(this.HTTPControl(this.PageData['KatkidaBulunanAjanslar'][1]["Web"]));
			temp.push(this.PageData['KatkidaBulunanAjanslar'][1]["Network"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][1]["Holding"]);
		}
		else {
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
		}
		temp.push('');
		if (this.PageData['KatkidaBulunanAjanslar'].length > 2) {
			temp.push(this.PageData['KatkidaBulunanAjanslar'][2]["Ajans"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][2]["Il"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][2]["Ulke"]);
			temp.push(this.HTTPControl(this.PageData['KatkidaBulunanAjanslar'][2]["Web"]));
			temp.push(this.PageData['KatkidaBulunanAjanslar'][2]["Network"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][2]["Holding"]);
		}
		else {
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
		}
		temp.push('');
		if (this.PageData['KatkidaBulunanAjanslar'].length > 3) {
			temp.push(this.PageData['KatkidaBulunanAjanslar'][3]["Ajans"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][3]["Il"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][3]["Ulke"]);
			temp.push(this.HTTPControl(this.PageData['KatkidaBulunanAjanslar'][3]["Web"]));
			temp.push(this.PageData['KatkidaBulunanAjanslar'][3]["Network"]);
			temp.push(this.PageData['KatkidaBulunanAjanslar'][3]["Holding"]);
		}
		else {
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
			temp.push('');
		}
		temp.push(this.PageData["FormuDolduranAdi"]);
		temp.push(this.PageData["FormuDolduranGorevi"]);
		temp.push(this.PageData["FormuDolduranTelefon"]);
		temp.push(this.PageData["FormuDolduranEposta"]);
		temp.push(this.PageData["Tarih"].toString());
		temp.push(this.PageData["GuncellemeTarihi"].toString());
		temp.push(this.PageData["Adim9SayfaSayisi"].toString());
		temp.push(parseInt(this.PageData["TamamlanmaOrani"]));
		temp.push(parseInt(this.PageData["Price"]));
		if (this.PageData['Dosyalar'].length > 0) {
			temp.push(this.HTTPControl(this.PageData['Dosyalar'][0]['DosyaLink']));
		}
		else {
			temp.push('');
		}
		if (this.PageData['Dosyalar'].length > 1) {
			temp.push(this.HTTPControl(this.PageData['Dosyalar'][1]['DosyaLink']));
		}
		else {
			temp.push('');
		}
		if (this.PageData['Dosyalar'].length > 2) {
			temp.push(this.HTTPControl(this.PageData['Dosyalar'][2]['DosyaLink']));
		}
		else {
			temp.push('');
		}
		if (this.PageData['Dosyalar'].length > 3) {
			temp.push(this.HTTPControl(this.PageData['Dosyalar'][3]['DosyaLink']));
		}
		else {
			temp.push('');
		}
		if (this.PageData['Dosyalar'].length > 4) {
			temp.push(this.HTTPControl(this.PageData['Dosyalar'][4]['DosyaLink']));
		}
		else {
			temp.push('');
		}
		if (this.PageData['Dosyalar'].length > 5) {
			temp.push(this.HTTPControl(this.PageData['Dosyalar'][5]['DosyaLink']));
		}
		else {
			temp.push('');
		}
		this.data.push(temp);

		// console.log(this.data)
		// return
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, this.fileName);
	}

	TarihFormatla(Tarih) {
		var options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}

	HTTPControl(link: string) {
		if (link.trim().length) {
			if (!link.includes("http")) {
				link = 'https://' + link;
			}
		}
		return link;
	}

	phoneMask(event) {
		// console.log(event)
		var num = event.replace(/\D/g, '');
		// console.log(num)
		num = num.replace('(', '');
		num = num.replace(')', '');
		num = num.replace('-', '');
		// console.log(num.trim().length)

		var yeni = '';
		if (num.substring(0, 1) == "0") {
			this.PageData['Adim2'].FormuDolduran.Tel = yeni;
			this.toastr.error("0 ile başlayamaz");
			return;
		}
		if (num.trim().length < 1) {
			yeni = '';
		}
		else if (num.trim().length >= 1 && num.trim().length < 4) {
			yeni = '(' + num.substring(0, 3);
		}
		else if (num.trim().length >= 4 && num.trim().length < 4) {
			yeni = '(' + num.substring(0, 3) + ') ';
		}
		else if (num.trim().length >= 4 && num.trim().length < 7) {
			// console.log("5-8")
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6);
		}
		else if (num.trim().length >= 7 && num.trim().length < 9) {
			// console.log("8-10")
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8);
		}
		else if (num.trim().length >= 9 && num.trim().length < 11) {
			// console.log("10-12")
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8) + '-' + num.substring(8, 10);
		}
		else {
			// console.log("12-14")
			yeni = '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, 8) + '-' + num.substring(8, 10);
		}

		// console.log(yeni)
		this.PageData['Adim2'].FormuDolduran.Tel = yeni;
		// $(this).val('(' + num.substring(0,3) + ')' + num.substring(3,6) + '-' + num.substring(6,10)); 
	}

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	DosyaType(text) {
		if (text == "text/plain") {
			text = "txt"
		}
		else if (text == "application/msword") {
			text = "Word"
		} else if (text == "image/jpeg") {
			text = "Resim"
		}
		else if (text == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
			text = "Excel"
		}
		else if (text == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
			text = "Word"
		}
		else {

		}
		return text
	}

	HtmlOlustur() {
		this.PDFHtml = '<style>p{margin-top:0px; margin-bottom: 0px;padding-top:0px; padding-bottom: 0px;}img{max-width:100%;}</style>';
		this.PDFHtml += '<table style="width: 580px; height: 40px; background-color: #000; border-collapse: collapse;" align="center" border = "0">'
		this.PDFHtml += '<tr>'
		this.PDFHtml += '<td style="width:125px; height: 40px;" > <img style="width:100px; height: 40px;" src="http://api.effieturkiye.org/storage/blackbackground.png" /></td>'
		this.PDFHtml += '<td valign = "middle" style = "width:380px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; line-height: 60px; text-align:center;">Effie ' + this.PageData['Yil'] + ' Başvuru Formu</td>'
		this.PDFHtml += '<td style = "width:125px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; text-align:center;">&nbsp;</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '</table>';
		this.PDFHtml += '<br>'

		this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml += '<tbody>'
		this.PDFHtml += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Künye</td></tr>'
		this.PDFHtml += '</tbody>'
		this.PDFHtml += '</table>'

		this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml += '<tbody>'
		this.PDFHtml += '<tr>'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka Adı:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaAdi'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr>'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka İli:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaIl'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr>'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka Ülkesi:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaUlke'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr>'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Adı:</td>'
		this.PDFHtml += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KampanyaAdi'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Kategori Adı:</td>'
		this.PDFHtml += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Kategori'] + ' ' + this.PageData['SubKategori'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '</tbody>'
		this.PDFHtml += '</table>'
		this.PDFHtml += '<br>'

		this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml += '<tbody>'
		this.PDFHtml += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Reklamveren</td></tr>'
		this.PDFHtml += '</tbody>'
		this.PDFHtml += '</table>'

		this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml += '<tbody>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Reklamveren Adı:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenAdi'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Adresi:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenAdres'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenIl'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenUlke'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Network & Holding:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenHolding'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData['ReklamverenWeb'] + '">' + this.PageData['ReklamverenWeb'] + '</a></td>'
		this.PDFHtml += '</tr>'

		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Yetkililer:</td>'
		var ReklamverenYetkililer = '';
		for (var i = 0; i < this.PageData['ReklamverenYetkililer'].length; i++) {
			if (i > 0) {
				ReklamverenYetkililer = ReklamverenYetkililer + "<br>";
			}
			ReklamverenYetkililer = ReklamverenYetkililer + this.PageData['ReklamverenYetkililer'][i]['AdSoyad'] + ', <a href="mailto:' + this.PageData['ReklamverenYetkililer'][i]['Eposta'] + '">' + this.PageData['ReklamverenYetkililer'][i]['Eposta'] + '</a>';
		}
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + ReklamverenYetkililer + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '</tbody>'
		this.PDFHtml += '</table>'
		this.PDFHtml += '<br>'

		this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml += '<tbody>'
		this.PDFHtml += '<tr>'
		this.PDFHtml += '<td style="color:#fff; font-size: 14px; text-align:center;">Başvuran Ana Ajans</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '</tbody>'
		this.PDFHtml += '</table>'
		this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml += '<tbody>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansAdi'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Adresi:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansAdres'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansIl'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansUlke'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Network:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansNetwork'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Ajans Holding:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansHolding'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData['BasvuranAjansWeb'] + '">' + this.PageData['BasvuranAjansWeb'] + '</a></td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Yetkililer:</td>'
		var BasvuranAjansYetkililer = '';
		for (var i = 0; i < this.PageData['BasvuranAjansYetkililer'].length; i++) {
			if (i > 0) {
				BasvuranAjansYetkililer = BasvuranAjansYetkililer + "<br>";
			}
			BasvuranAjansYetkililer = BasvuranAjansYetkililer + this.PageData['BasvuranAjansYetkililer'][i]['AdSoyad'] + ', <a href="mailto:' + this.PageData['BasvuranAjansYetkililer'][i]['Eposta'] + '">' + this.PageData['BasvuranAjansYetkililer'][i]['Eposta'] + '</a>';
		}
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + BasvuranAjansYetkililer + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '</tbody>'
		this.PDFHtml += '</table>'
		this.PDFHtml += '<br>'

		for (var i = 0; i < this.PageData['KatkidaBulunanAjanslar'].length; i++) {
			this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
			this.PDFHtml += '<tbody>'
			this.PDFHtml += '<tr>'
			this.PDFHtml += '<td style="color:#fff; font-size: 14px; text-align:center;">Katkıda Bulunan Ajans ' + (i + 1) + '</td>'
			this.PDFHtml += '</tr>'
			this.PDFHtml += '</tbody>'
			this.PDFHtml += '</table>'


			this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
			this.PDFHtml += '<tbody>'
			this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı:</td>'
			this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Ajans'] + '</td>'
			this.PDFHtml += '</tr>'
			this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
			this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Il'] + '</td>'
			this.PDFHtml += '</tr>'
			this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
			this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Ulke'] + '</td>'
			this.PDFHtml += '</tr>'
			this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Network:</td>'
			this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Network'] + '</td>'
			this.PDFHtml += '</tr>'
			this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Ajans Holding:</td>'
			this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Holding'] + '</td>'
			this.PDFHtml += '</tr>'
			this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
			this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData['KatkidaBulunanAjanslar'][i]['Web'] + '">' + this.PageData['KatkidaBulunanAjanslar'][i]['Web'] + '</a></td>'
			this.PDFHtml += '</tr>'
			this.PDFHtml += '</tbody>'
			this.PDFHtml += '</table>'
			this.PDFHtml += '<br>'
		}

		this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml += '<tbody>'
		this.PDFHtml += '<tr>'
		this.PDFHtml += '<td style="color:#fff; font-size: 14px; text-align:center;">Formu Dolduran</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '</tbody>'
		this.PDFHtml += '</table>'
		this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml += '<tbody>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı, Soyadı:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['FormuDolduranAdi'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Görevi:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['FormuDolduranGorevi'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">Telefon:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['FormuDolduranTelefon'] + '</td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml += '<td style="width: 100px; color:#fff; font-size: 12px;">E-Posta:</td>'
		this.PDFHtml += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="mailto:' + this.PageData['FormuDolduranEposta'] + '">' + this.PageData['FormuDolduranEposta'] + '</a></td>'
		this.PDFHtml += '</tr>'
		this.PDFHtml += '</tbody>'
		this.PDFHtml += '</table>'
		this.PDFHtml += '<br>'

		this.PDF1Icerik();

		///PDF2
		this.PDFHtml2 = '<style>p{margin-top:0px; margin-bottom: 0px;padding-top:0px; padding-bottom: 0px;}img{max-width:100%;}</style>';
		this.PDFHtml2 += '<table style="width: 580px; height: 40px; background-color: #000; border-collapse: collapse;" align="center" border = "0">'
		this.PDFHtml2 += '<tr>'
		this.PDFHtml2 += '<td style="width:125px; height: 40px;" > <img style="width:100px; height: 40px;" src="http://api.effieturkiye.org/storage/blackbackground.png" /></td>'
		this.PDFHtml2 += '<td valign = "middle" style = "width:380px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; line-height: 60px; text-align:center;">Effie ' + this.PageData['Yil'] + ' Başvuru Formu</td>'
		this.PDFHtml2 += '<td style = "width:125px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; text-align:center;">&nbsp;</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '</table>';
		this.PDFHtml2 += '<br>'

		this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml2 += '<tbody>'
		this.PDFHtml2 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Künye</td></tr>'
		this.PDFHtml2 += '</tbody>'
		this.PDFHtml2 += '</table>'

		this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml2 += '<tbody>'
		this.PDFHtml2 += '<tr>'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka Adı:</td>'
		this.PDFHtml2 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaAdi'] + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr>'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Adı:</td>'
		this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KampanyaAdi'] + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kategori Adı:</td>'
		this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Kategori'] + ' ' + this.PageData['SubKategori'] + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '</tbody>'
		this.PDFHtml2 += '</table>'
		this.PDFHtml2 += '<br>'

		this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml2 += '<tbody>'
		this.PDFHtml2 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Özet</td></tr>'
		this.PDFHtml2 += '</tbody>'
		this.PDFHtml2 += '</table>'

		this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml2 += '<tbody>'
		this.PDFHtml2 += '<tr>'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Başlangıç Tarihi:</td>'
		this.PDFHtml2 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].BasGun + '.' + this.AyDondur(this.PageData['Adim3'].BasAy) + '.' + this.PageData['Adim3'].BasYil + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr>'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Bitiş Tarihi:</td>'
		if (this.PageData['Adim3'].DevamEdiyor == 1) {
			this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">Devam Ediyor</td>'
		}
		else {
			this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].BitGun + '.' + this.AyDondur(this.PageData['Adim3'].BitAy) + '.' + this.PageData['Adim3'].BitYil + '</td>'
		}

		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Zorluk:</td>'
		this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir2 + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">İçgörü:</td>'
		this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir3 + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Büyük Fikir:</td>'
		this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir4 + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Fikrin Hayata Geçmesi:</td>'
		this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir5 + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;">Sonuç:</td>'
		this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir6 + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml2 += '<td style="width: 100px; color:#fff; font-size: 12px;" colspan="2">Pazarlama iletişim etkinliği kapsamında bu vakanın neden bu kategoride ödül alması gerektiğini düşünüyorsunuz? Vakanızı elde ettiğiniz sonuçların zorluğu aşmakla ilişkisini kurarak özetleyiniz. İki ayrı kategoriden giren kampanyalar için bu özet farklı olmalıdır.</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml2 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;" colspan="2">' + this.PageData['Adim3'].Satir8 + '</td>'
		this.PDFHtml2 += '</tr>'
		this.PDFHtml2 += '</tbody>'
		this.PDFHtml2 += '</table>'
		this.PDFHtml2 += '<br>'

		this.PDF2Icerik();

		this.PDFHtml3 = '<style>p{margin-top:0px; margin-bottom: 0px;padding-top:0px; padding-bottom: 0px;}img{max-width:100%;}</style>';
		this.PDFHtml3 += '<table style="width: 580px; height: 40px; background-color: #000; border-collapse: collapse;" align="center" border = "0">'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width:125px; height: 40px;" > <img style="width:100px; height: 40px;" src="http://api.effieturkiye.org/storage/blackbackground.png" /></td>'
		this.PDFHtml3 += '<td valign = "middle" style = "width:380px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; line-height: 60px; text-align:center;">Effie ' + this.PageData['Yil'] + ' Başvuru Formu</td>'
		this.PDFHtml3 += '<td style = "width:125px; height: 40px; vertical-align: middle; color:#fff; font-size: 16px; text-align:center;">&nbsp;</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</table>';
		this.PDFHtml3 += '<br>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Künye</td></tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka Adı:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaAdi'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka İli:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaIl'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Marka Ülkesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['MarkaUlke'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Adı:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KampanyaAdi'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kategori Adı:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Kategori'] + ' ' + this.PageData['SubKategori'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Reklamveren</td></tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Reklamveren Adı:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenAdi'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adresi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenAdres'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenIl'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenUlke'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Network & Holding:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['ReklamverenHolding'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData['ReklamverenWeb'] + '">' + this.PageData['ReklamverenWeb'] + '</a></td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Yetkililer:</td>'
		var ReklamverenYetkililer = '';
		for (var i = 0; i < this.PageData['ReklamverenYetkililer'].length; i++) {
			if (i > 0) {
				ReklamverenYetkililer = ReklamverenYetkililer + "<br>";
			}
			ReklamverenYetkililer = ReklamverenYetkililer + this.PageData['ReklamverenYetkililer'][i]['AdSoyad'] + ', <a href="mailto:' + this.PageData['ReklamverenYetkililer'][i]['Eposta'] + '">' + this.PageData['ReklamverenYetkililer'][i]['Eposta'] + '</a>';
		}
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + ReklamverenYetkililer + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">Başvuran Ana Ajans</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansAdi'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adresi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansAdres'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansIl'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansUlke'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Network:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansNetwork'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Ajans Holding:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['BasvuranAjansHolding'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData['BasvuranAjansWeb'] + '">' + this.PageData['BasvuranAjansWeb'] + '</a></td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Yetkililer:</td>'
		var BasvuranAjansYetkililer = '';
		for (var i = 0; i < this.PageData['BasvuranAjansYetkililer'].length; i++) {
			if (i > 0) {
				BasvuranAjansYetkililer = BasvuranAjansYetkililer + "<br>";
			}
			BasvuranAjansYetkililer = BasvuranAjansYetkililer + this.PageData['BasvuranAjansYetkililer'][i]['AdSoyad'] + ', <a href="mailto:' + this.PageData['BasvuranAjansYetkililer'][i]['Eposta'] + '">' + this.PageData['BasvuranAjansYetkililer'][i]['Eposta'] + '</a>';
		}
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + BasvuranAjansYetkililer + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>'

		for (var i = 0; i < this.PageData['KatkidaBulunanAjanslar'].length; i++) {
			this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
			this.PDFHtml3 += '<tbody>'
			this.PDFHtml3 += '<tr>'
			this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">Katkıda Bulunan Ajans ' + (i + 1) + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '</tbody>'
			this.PDFHtml3 += '</table>'


			this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
			this.PDFHtml3 += '<tbody>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Ajans'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">İli:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Il'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Ülkesi:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Ulke'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Network:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Network'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Bağlı Olduğu Ajans Holding:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['KatkidaBulunanAjanslar'][i]['Holding'] + '</td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
			this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Web Sitesi:</td>'
			this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="' + this.PageData['KatkidaBulunanAjanslar'][i]['Web'] + '">' + this.PageData['KatkidaBulunanAjanslar'][i]['Web'] + '</a></td>'
			this.PDFHtml3 += '</tr>'
			this.PDFHtml3 += '</tbody>'
			this.PDFHtml3 += '</table>'
			this.PDFHtml3 += '<br>'
		}

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">Formu Dolduran</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Adı, Soyadı:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['FormuDolduranAdi'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Görevi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['FormuDolduranGorevi'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Telefon:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['FormuDolduranTelefon'] + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">E-Posta:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;"><a href="mailto:' + this.PageData['FormuDolduranEposta'] + '">' + this.PageData['FormuDolduranEposta'] + '</a></td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr><td style="color:#fff; font-size: 14px; text-align:center;">Özet</td></tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'

		this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
		this.PDFHtml3 += '<tbody>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Başlangıç Tarihi:</td>'
		this.PDFHtml3 += '<td style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].BasGun + '.' + this.AyDondur(this.PageData['Adim3'].BasAy) + '.' + this.PageData['Adim3'].BasYil + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr>'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Kampanya Bitiş Tarihi:</td>'
		if (this.PageData['Adim3'].DevamEdiyor == 1) {
			this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">Devam Ediyor</td>'
		}
		else {
			this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].BitGun + '.' + this.AyDondur(this.PageData['Adim3'].BitAy) + '.' + this.PageData['Adim3'].BitYil + '</td>'
		}

		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Zorluk:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir2 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">İçgörü:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir3 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Büyük Fikir:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir4 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;">Fikrin Hayata Geçmesi:</td>'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;">' + this.PageData['Adim3'].Satir5 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td style="width: 100px; color:#fff; font-size: 12px;" colspan="2">Pazarlama iletişim etkinliği kapsamında bu vakanın neden bu kategoride ödül alması gerektiğini düşünüyorsunuz? Vakanızı elde ettiğiniz sonuçların zorluğu aşmakla ilişkisini kurarak özetleyiniz. İki ayrı kategoriden giren kampanyalar için bu özet farklı olmalıdır.</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
		this.PDFHtml3 += '<td  style="width: 480px; font-size: 12px; background-color:#fff;border-right: 1px solid #b1985c;border-bottom: 1px solid #b1985c;" colspan="2">' + this.PageData['Adim3'].Satir8 + '</td>'
		this.PDFHtml3 += '</tr>'
		this.PDFHtml3 += '</tbody>'
		this.PDFHtml3 += '</table>'
		this.PDFHtml3 += '<br>'

		this.PDF3Icerik();

		this.SayfaOlustur();
	}

	PDF1Icerik() {
		var index = -1;
		this.PageData['SoruCevap'].forEach(element => {
			// console.log(element);
			index++
			if (element.Cevap.Type === 'SoruText') {
				this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml += '<tbody>'
				this.PDFHtml += '<tr>'
				this.PDFHtml += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml += '</tr>'
				this.PDFHtml += '</tbody>'
				this.PDFHtml += '</table>'
				var cevap = '';
				// if (element.Cevap.Cevap !== null) {
				// 	cevap = element.Cevap.Cevap.replace(/<img /g, '<img style="width:480px; text-align: center;" ');
				// 	cevap = cevap.trim()
				// 	cevap = element.Cevap.Cevap.replace(/src="https/g, 'src="http');
				// }
				if (element.Cevap.Cevap !== null) {
					cevap = element.Cevap.Cevap.replace(/<img[^>]*>/g, "")
					cevap = cevap.replace("<p></p>", "")
					cevap = cevap.replace("undefined", "")
					cevap = cevap.replace("<p><br></p>", "")
					cevap = cevap.trim()
				}

				this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml += '<tbody>'
				this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
				this.PDFHtml += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + cevap + '</td>'
				this.PDFHtml += '</tr>'
				this.PDFHtml += '</tbody>'
				this.PDFHtml += '</table>'
				this.PDFHtml += '<br>'
			}
			else if (element.Cevap.Type === 'Radio') {
				this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml += '<tbody>'
				this.PDFHtml += '<tr>'
				this.PDFHtml += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml += '</tr>'
				this.PDFHtml += '</tbody>'
				this.PDFHtml += '</table>'

				this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml += '<tbody>'
				this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
				var Radio = '';
				for (var i = 0; i < element.Cevap.Radios.length; i++) {
					if (element.Cevap.Radios[i].Value === true) {
						Radio = element.Cevap.Radios[i].Name;
					}
				}
				this.PDFHtml += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + Radio + '</td>'
				this.PDFHtml += '</tr>'
				this.PDFHtml += '</tbody>'
				this.PDFHtml += '</table>'
				this.PDFHtml += '<br>'
			}
			else if (element.Cevap.Type === 'CheckBox') {
				this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml += '<tbody>'
				this.PDFHtml += '<tr>'
				this.PDFHtml += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml += '</tr>'
				this.PDFHtml += '</tbody>'
				this.PDFHtml += '</table>'

				this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml += '<tbody>'
				this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
				var CheckBoxes = '';
				var say = 0;
				for (var i = 0; i < element.Cevap.CheckBoxes.length; i++) {
					if (element.Cevap.CheckBoxes[i]["Value"] === true) {
						if (say > 0) {
							CheckBoxes = CheckBoxes + "<br>";
						}
						if (element.Cevap.CheckBoxes[i]["Type"] === "CheckBox") {
							CheckBoxes = CheckBoxes + element.Cevap.CheckBoxes[i]["Name"];
						}
						else {
							CheckBoxes = CheckBoxes + "Diğer: " + element.Cevap.CheckBoxes[i]["Cevap"];
						}
						say++;
					}
				}
				this.PDFHtml += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + CheckBoxes + '</td>'
				this.PDFHtml += '</tr>'
				this.PDFHtml += '</tbody>'
				this.PDFHtml += '</table>'
				this.PDFHtml += '<br>'
			}
			else if (element.Cevap.Type === 'MultiCheckBox') {
				this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml += '<tbody>'
				this.PDFHtml += '<tr>'
				this.PDFHtml += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml += '</tr>'
				this.PDFHtml += '</tbody>'
				this.PDFHtml += '</table>'

				this.PDFHtml += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml += '<tbody>'
				this.PDFHtml += '<tr style="border: 1px solid #b1985c;">'
				var CheckBoxes = '';
				for (var i = 0; i < element.Cevap.CheckBoxes.length; i++) {
					var temp = '';
					// console.log(element.Cevap.CheckBoxes[i]["SubCheckBoxes"]);
					for (var x = 0; x < element.Cevap.CheckBoxes[i]["SubCheckBoxes"].length; x++) {
						if (element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Value"] === true) {
							if (temp.length > 0) {
								temp = temp + ", "
							}
							if (element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Type"] === "CheckBox") {
								temp = temp + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Name"];
							}
							else {
								temp = temp + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Name"] + ": " + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Cevap"];
							}
						}
					}
					if (temp.length > 0) {
						if (i > 0) {
							CheckBoxes = CheckBoxes + "<br>";
						}
						CheckBoxes = CheckBoxes + "<b>" + element.Cevap.CheckBoxes[i]["Grup"] + ": </b>"
						CheckBoxes = CheckBoxes + temp;
					}
				}
				this.PDFHtml += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + CheckBoxes + '</td>'
				this.PDFHtml += '</tr>'
				this.PDFHtml += '</tbody>'
				this.PDFHtml += '</table>'
				this.PDFHtml += '<br>'
			}
		});
	}

	PDF2Icerik() {
		var index = -1;
		this.PageData['SoruCevap'].forEach(element => {
			index++
			if (element.Cevap.Type === 'SoruText') {
				this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml2 += '<tbody>'
				this.PDFHtml2 += '<tr>'
				this.PDFHtml2 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml2 += '</tr>'
				this.PDFHtml2 += '</tbody>'
				this.PDFHtml2 += '</table>'
				var cevap = '';
				if (element.Cevap.Cevap !== null) {
					cevap = element.Cevap.Cevap.replace(/<p><img /g, '<p style="text-align: center;"><img style="max-width:480px; text-align: center;" ');
					cevap = cevap.trim();
					cevap = cevap.replace(/src="https/g, 'src="http');
					// console.log(cevap)
				}
				this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml2 += '<tbody>'
				this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
				this.PDFHtml2 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + cevap + '</td>'
				this.PDFHtml2 += '</tr>'
				this.PDFHtml2 += '</tbody>'
				this.PDFHtml2 += '</table>'
				this.PDFHtml2 += '<br>'
			}
			else if (element.Cevap.Type === 'Radio') {
				this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml2 += '<tbody>'
				this.PDFHtml2 += '<tr>'
				this.PDFHtml2 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml2 += '</tr>'
				this.PDFHtml2 += '</tbody>'
				this.PDFHtml2 += '</table>'

				this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml2 += '<tbody>'
				this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
				var Radio = '';
				for (var i = 0; i < element.Cevap.Radios.length; i++) {
					if (element.Cevap.Radios[i].Value === true) {
						Radio = element.Cevap.Radios[i].Name;
					}
				}
				this.PDFHtml2 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + Radio + '</td>'
				this.PDFHtml2 += '</tr>'
				this.PDFHtml2 += '</tbody>'
				this.PDFHtml2 += '</table>'
				this.PDFHtml2 += '<br>'
			}
			else if (element.Cevap.Type === 'CheckBox') {
				this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml2 += '<tbody>'
				this.PDFHtml2 += '<tr>'
				this.PDFHtml2 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml2 += '</tr>'
				this.PDFHtml2 += '</tbody>'
				this.PDFHtml2 += '</table>'

				this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml2 += '<tbody>'
				this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
				var CheckBoxes = '';
				var say = 0;
				for (var i = 0; i < element.Cevap.CheckBoxes.length; i++) {
					if (element.Cevap.CheckBoxes[i]["Value"] === true) {
						if (say > 0) {
							CheckBoxes = CheckBoxes + "<br>";
						}
						if (element.Cevap.CheckBoxes[i]["Type"] === "CheckBox") {
							CheckBoxes = CheckBoxes + element.Cevap.CheckBoxes[i]["Name"];
						}
						else {
							CheckBoxes = CheckBoxes + "Diğer: " + element.Cevap.CheckBoxes[i]["Cevap"];
						}
						say++;
					}
				}
				this.PDFHtml2 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + CheckBoxes + '</td>'
				this.PDFHtml2 += '</tr>'
				this.PDFHtml2 += '</tbody>'
				this.PDFHtml2 += '</table>'
				this.PDFHtml2 += '<br>'
			}
			else if (element.Cevap.Type === 'MultiCheckBox') {
				this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml2 += '<tbody>'
				this.PDFHtml2 += '<tr>'
				this.PDFHtml2 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml2 += '</tr>'
				this.PDFHtml2 += '</tbody>'
				this.PDFHtml2 += '</table>'

				this.PDFHtml2 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml2 += '<tbody>'
				this.PDFHtml2 += '<tr style="border: 1px solid #b1985c;">'
				var CheckBoxes = '';
				for (var i = 0; i < element.Cevap.CheckBoxes.length; i++) {
					var temp = ""
					for (var x = 0; x < element.Cevap.CheckBoxes[i]["SubCheckBoxes"].length; x++) {
						if (element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Value"] === true) {

							if (temp.length > 0) {
								temp = temp + ", "
							}
							if (element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Type"] === "CheckBox") {
								temp = temp + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Name"];
							}
							else {
								temp = temp + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Name"] + ": " + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Cevap"];
							}
						}
					}
					if (temp.length > 0) {
						if (i > 0) {
							CheckBoxes = CheckBoxes + "<br>";
						}
						CheckBoxes = CheckBoxes + "<b>" + element.Cevap.CheckBoxes[i]["Grup"] + ": </b>"
						CheckBoxes = CheckBoxes + temp;
					}
				}
				this.PDFHtml2 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + CheckBoxes + '</td>'
				this.PDFHtml2 += '</tr>'
				this.PDFHtml2 += '</tbody>'
				this.PDFHtml2 += '</table>'
				this.PDFHtml2 += '<br>'
			}
		});
	}

	PDF3Icerik() {
		var index = -1;
		this.PageData['SoruCevap'].forEach(element => {
			index++
			if (element.Cevap.Type === 'SoruText') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				var cevap = '';
				if (element.Cevap.Cevap !== null) {
					// cevap = element.Cevap.Cevap.replace(/<img /g, '<img style="width:580px;" ')
					cevap = element.Cevap.Cevap.replace(/<p><img /g, '<p style="text-align: center;"><img style="max-width:480px; text-align: center;" ');
					cevap = cevap.trim();
					cevap = cevap.replace(/src="https/g, 'src="http');
				}
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + cevap + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
			else if (element.Cevap.Type === 'Radio') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'

				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				var Radio = '';
				for (var i = 0; i < element.Cevap.Radios.length; i++) {
					if (element.Cevap.Radios[i].Value === true) {
						Radio = element.Cevap.Radios[i].Name;
					}
				}
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + Radio + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
			else if (element.Cevap.Type === 'CheckBox') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'

				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				var CheckBoxes = '';
				var say = 0;
				for (var i = 0; i < element.Cevap.CheckBoxes.length; i++) {
					if (element.Cevap.CheckBoxes[i]["Value"] === true) {
						if (say > 0) {
							CheckBoxes = CheckBoxes + "<br>";
						}
						if (element.Cevap.CheckBoxes[i]["Type"] === "CheckBox") {
							CheckBoxes = CheckBoxes + element.Cevap.CheckBoxes[i]["Name"];
						}
						else {
							CheckBoxes = CheckBoxes + "Diğer: " + element.Cevap.CheckBoxes[i]["Cevap"];
						}
						say++;
					}
				}
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + CheckBoxes + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
			else if (element.Cevap.Type === 'MultiCheckBox') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'

				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				var CheckBoxes = '';
				for (var i = 0; i < element.Cevap.CheckBoxes.length; i++) {
					var temp = ""
					for (var x = 0; x < element.Cevap.CheckBoxes[i]["SubCheckBoxes"].length; x++) {
						if (element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Value"] === true) {

							if (temp.length > 0) {
								temp = temp + ", "
							}
							if (element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Type"] === "CheckBox") {
								temp = temp + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Name"];
							}
							else {
								temp = temp + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Name"] + ": " + element.Cevap.CheckBoxes[i]["SubCheckBoxes"][x]["Cevap"];
							}
						}
					}
					if (temp.length > 0) {
						if (i > 0) {
							CheckBoxes = CheckBoxes + "<br>";
						}
						CheckBoxes = CheckBoxes + "<b>" + element.Cevap.CheckBoxes[i]["Grup"] + ": </b>"
						CheckBoxes = CheckBoxes + temp;
					}
				}
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + CheckBoxes + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
			else if (element.Cevap.Type === 'Kaynak') {
				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr>'
				this.PDFHtml3 += '<td style="color:#fff; font-size: 14px; text-align:center;">' + this.PageData['Sorular'][index].Soru.Soru + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				var cevap = '';
				if (element.Cevap.Cevap !== null) {
					cevap = element.Cevap.Cevap.replace(/<img /g, '<img style="width:580px;" ')
					cevap = cevap.trim()
					cevap = element.Cevap.Cevap.replace(/src="https/g, 'src="http');
					cevap = cevap.replace(".jpg?alt=media", "_600x1300.jpg?alt=media")
					cevap = cevap.replace(".png?alt=media", "_600x1300.jpg?alt=media")
					cevap = cevap.replace(".jpeg?alt=media", "_600x1300.jpg?alt=media")
					cevap = cevap.replace(".bmp?alt=media", "_600x1300.jpg?alt=media")
					cevap = cevap.replace(".gif?alt=media", "_600x1300.jpg?alt=media")
				}

				this.PDFHtml3 += '<table style="width: 580px; background-color:#b1985c;" align="center" border="0">'
				this.PDFHtml3 += '<tbody>'
				this.PDFHtml3 += '<tr style="border: 1px solid #b1985c;">'
				this.PDFHtml3 += '<td style="width: 580px; font-size: 12px; background-color:#fff;border: 1px solid #b1985c;">' + cevap + '</td>'
				this.PDFHtml3 += '</tr>'
				this.PDFHtml3 += '</tbody>'
				this.PDFHtml3 += '</table>'
				this.PDFHtml3 += '<br>'
			}
		});
	}

	SayfaOlustur() {
		// console.log(this.PDFHtml3)
		// return;
		this.toastr.warning("PDF Oluşturuluyor. Zaman alabilir, lütfen bekleyiniz.");
		this.isLoading = true;
		const params = new HttpParams();
		let headers = new HttpHeaders()
			.append('Content-Type', 'application/json; charset=utf-8');
		// http://localhost:3000/
		// console.log(this.PDFHtml)
		this.http.post("https://pdfebook.effieturkiye.org/", { ProjectId: this.ProjeId.toString(), icerik: this.PDFHtml, icerik2: this.PDFHtml2, icerik3: this.PDFHtml3, Surdurulebilir: this.PageData['Surdurulebilir'] }, {
			params: params,
			headers: headers
		})
			.subscribe((data: any) => {
				this.isLoading = false;
				if (data.Durum !== 1) {
					this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
					return;
				}
				// this.toastr.success("Sayfa sayısı doğrulama PDF’i ve Jüri PDF’i oluşturuldu. İndirebilirsiniz.");
				this.PageData['Adim9Tarih'] = data.Tarih.replace(' ', 'T');
				this.PageData['Adim9SayfaSayisi'] = data.PageNumber;
				if (data.PageNumber > 0 && data.PageNumber <= data.MaxSayfa) {
					this.toastr.success("Sayfa sayısı doğrulama PDF’i ve Jüri PDF’i oluşturuldu. İndirebilirsiniz.");
				}
				else if (data.PageNumber > 0 && data.PageNumber > data.MaxSayfa) {
					this.toastr.error("Max. sayfa sayısını geçtiniz.");
				}
				// else
				// {

				// }


				// console.log(this.globals.projeler);
				this.DataYukle();
				// window.location.href = "http://localhost:3000/pdf/pdf/" + this.ProjeId + "/1.pdf";
			},
				err => {
					this.isLoading = false;
					this.toastr.error("Bir hata oluştu, Lütfen tekrar deneyiniz");
				});
	}

	AyDondur(ay: number) {
		if (ay === 0) {
			return "";
		}
		else if (ay === 1) {
			return "Ocak";
		}
		else if (ay === 2) {
			return "Şubat";
		}
		else if (ay === 3) {
			return "Mart";
		}
		else if (ay === 4) {
			return "Nisan";
		}
		else if (ay === 5) {
			return "Mayıs";
		}
		else if (ay === 6) {
			return "Haziran";
		}
		else if (ay === 7) {
			return "Temmuz";
		}
		else if (ay === 8) {
			return "Ağustos";
		}
		else if (ay === 9) {
			return "Eylül";
		}
		else if (ay === 10) {
			return "Ekim";
		}
		else if (ay === 11) {
			return "Kasım";
		}
		else if (ay === 12) {
			return "Aralık";
		}
	}


}
