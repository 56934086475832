import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from "./shared/shared.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
//import * as $ from 'jquery';
import { FileUploadModule } from 'ng2-file-upload';
import { QuillModule } from 'ngx-quill' 
import { UiSwitchModule } from 'ngx-ui-switch';

import { environment } from '../environments/environment';

import { DashboardComponent } from './dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { KullanicilistesiComponent } from './kullanicilistesi/kullanicilistesi.component';
import { KullanicieditComponent } from './kullaniciedit/kullaniciedit.component';
import { JuriListesiComponent } from './jurilistesi/jurilistesi.component';
import { JuriEditComponent } from './juriedit/juriedit.component';
import { JuriEkleComponent } from './juriekle/juriekle.component';
import { ProjelerTamamlananListesiComponent } from './projelertamamlanan/projelertamamlanan.component';
import { ProjelerTamamlanmamisListesiComponent } from './projelertamamlanmamis/projelertamamlanmamis.component';
import { ProjelerSilinenlerListesiComponent } from './projelersilinenler/projelersilinenler.component';
import { ProjeEditComponent } from './projeedit/projeedit.component';
import { KategorilerComponent } from './kategoriler/kategoriler.component';
import { SayfalarComponent } from './sayfalar/sayfalar.component';
import { SorularComponent } from './sorular/sorular.component';
import { MessageServiceService } from './services/message-service.service';
import { Globals } from './providers/globals/globals';
import { LogoutComponent } from './logout/logout.component';
import { IdleComponent } from './idle/idle.component';
import { EditorGidenComponent } from './editorgiden/editorgiden.component';


@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        DashboardComponent,
        PageNotFoundComponent,
        KullanicilistesiComponent,
        KullanicieditComponent,
        JuriListesiComponent,
        JuriEditComponent,
        JuriEkleComponent,
        ProjelerTamamlananListesiComponent,
        ProjelerTamamlanmamisListesiComponent,
        ProjelerSilinenlerListesiComponent,
        ProjeEditComponent,
        EditorGidenComponent,
        KategorilerComponent,
        SayfalarComponent,
        SorularComponent,
        LogoutComponent,
        IdleComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        BrowserModule,
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        UiSwitchModule,
        QuillModule.forRoot(),
        ToastrModule.forRoot(),
        RouterModule.forRoot([
            {
                path: '',
                redirectTo: '/idle',
                pathMatch: 'full'
            },
            { path: 'idle', component: IdleComponent },
            {
                path: 'logout',
                component: ContentLayoutComponent,
                children: [
                    // { path: '', redirectTo: 'presentations', pathMatch: 'full' },
                    { path: '', component: LogoutComponent, data: { title: 'logout' } },
                    { path: '**', redirectTo: 'logout', pathMatch: 'full' },
                ]
            },
            {
                path: 'dashboard',
                component: FullLayoutComponent,
                children: [
                    // { path: '', redirectTo: 'presentations', pathMatch: 'full' },
                    { path: '', component: DashboardComponent, data: { title: 'Dashboard' } },
                    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
                ]
            },
            {
                path: 'kullanicilar',
                component: FullLayoutComponent,
                children: [
                    { path: '', redirectTo: 'liste', pathMatch: 'full' },
                    { path: 'liste', component: KullanicilistesiComponent },
                    { path: 'edit/:KullaniciId', component: KullanicieditComponent }
                    // { path: '**', redirectTo: 'all', pathMatch: 'full' },
                ]
            },
            {
                path: 'juriler',
                component: FullLayoutComponent,
                children: [
                    { path: '', redirectTo: 'liste', pathMatch: 'full' },
                    { path: 'liste', component: JuriListesiComponent },
                    { path: 'edit/:JuriId', component: JuriEditComponent },
                    { path: 'add', component: JuriEkleComponent }
                    // { path: '**', redirectTo: 'all', pathMatch: 'full' },
                ]
            },
            {
                path: 'tamamlananprojeler',
                component: FullLayoutComponent,
                children: [
                    { path: '', redirectTo: 'liste', pathMatch: 'full' },
                    { path: 'liste/:Yil', component: ProjelerTamamlananListesiComponent },
                    { path: 'edit/:ProjeId', component: ProjeEditComponent }
                    // { path: '**', redirectTo: 'all', pathMatch: 'full' },
                ]
            },
            {
                path: 'tamamlanmamisprojeler',
                component: FullLayoutComponent,
                children: [
                    { path: '', redirectTo: 'liste', pathMatch: 'full' },
                    { path: 'liste/:Yil', component: ProjelerTamamlanmamisListesiComponent },
                    { path: 'edit/:ProjeId', component: ProjeEditComponent }
                    // { path: '**', redirectTo: 'all', pathMatch: 'full' },
                ]
            },
            {
                path: 'editorgiden',
                component: FullLayoutComponent,
                children: [
                    { path: '', redirectTo: 'liste', pathMatch: 'full' },
                    { path: 'liste/:Yil', component: EditorGidenComponent },
                    // { path: '**', redirectTo: 'all', pathMatch: 'full' },
                ]
            },
            {
                path: 'silinenprojeler',
                component: FullLayoutComponent,
                children: [
                    { path: '', redirectTo: 'liste', pathMatch: 'full' },
                    { path: 'liste/:Yil', component: ProjelerSilinenlerListesiComponent },
                    { path: 'edit/:ProjeId', component: ProjeEditComponent }
                    // { path: '**', redirectTo: 'all', pathMatch: 'full' },
                ]
            },
            {
                path: 'kategoriler',
                component: FullLayoutComponent,
                children: [
                    // { path: '', redirectTo: 'presentations', pathMatch: 'full' },
                    { path: '', component: KategorilerComponent, data: { title: 'Kategoriler' } },
                    { path: '**', redirectTo: 'kategoriler', pathMatch: 'full' },
                ]
            },
            {
                path: 'sayfalar',
                component: FullLayoutComponent,
                children: [
                    // { path: '', redirectTo: 'presentations', pathMatch: 'full' },
                    { path: '', component: SayfalarComponent, data: { title: 'Site Ayarları' } },
                    { path: '**', redirectTo: 'sayfalar', pathMatch: 'full' },
                ]
            },
            {
                path: 'sorular',
                component: FullLayoutComponent,
                children: [
                    // { path: '', redirectTo: 'presentations', pathMatch: 'full' },
                    { path: '', component: SorularComponent, data: { title: 'Sorular' } },
                    { path: '**', redirectTo: 'sorular', pathMatch: 'full' },
                ]
            },

            { path: '**', component: PageNotFoundComponent }
        ]),
        SharedModule,
        FormsModule,
        NgxDatatableModule,
        NgbModule
    ],
    providers: [
        MessageServiceService,
        Globals,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }