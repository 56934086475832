import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import { ActivatedRoute } from '@angular/router';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
	selector: 'app-editorgiden',
	templateUrl: './editorgiden.component.html',
	styleUrls: ['./editorgiden.component.scss']
})
export class EditorGidenComponent implements OnInit {
	Yil = '';
	rows = [];
	temp = [];
	loading: boolean = false;

	data: AOA = [];
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: string = 'EditorGidenListesi.xlsx';
	Excel = {
		MarkaAdi: true,
		KampanyaAdi: true,
		Kategori: true,
		SubKategori: true,
		ReklamverenAd: true,
		ReklamverenAdres: true,
		ReklamverenYetkililer: true,
		BasvuranAjansAd: true,
		BasvuranAjansAdres: true,
		BasvuranAjansYetkililer: true,
		KatkidaBulunanAjanslar: true,
		FormuDolduranAd: true,
		FormuDolduranGorevi: true,
		FormuDolduranTel: true,
		FormuDolduranEposta: true,
		Tarih: true,
		GondermeTarihi: true,
		TamamlanmaOrani: true,
		SayfaSayisi: true,
		Onay: true
	};

	@ViewChild(DatatableComponent) table: DatatableComponent;

	constructor(
		private modalService: NgbModal,
		private toastr: ToastrService,
		private http: HttpClient,
		private globals: Globals,
		private activatedRoute: ActivatedRoute,
	) {
		// this.temp = [...data];
		// this.rows = data;
		this.activatedRoute.params
			.subscribe(params => {
				// console.log(params.ProjeId);
				this.Yil = params.Yil.toString();
			});
		this.DataYukle();
	}

	ngOnInit() {

	}

	DataYukle() {
		let params = new HttpParams();
		params = params.append('Yil', this.Yil);
		params = params.append('Durum', '1');
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/AdminEditorGidenProjeler', { params, headers }).subscribe(
			(response) => {
				// console.log(response);
				let TempJson = JSON.parse(JSON.stringify(response));
				TempJson.forEach(element => {
					element['Id'] = element['Id'].toString();
					element.ReklamverenYetkililer = '';
					for (var i = 0; i < element['ReklamverenYetkililer'].length; i++) {
						if (i > 0) {
							element.ReklamverenYetkililer = element.ReklamverenYetkililer + ', ';
						}
						element.ReklamverenYetkililer = element.ReklamverenYetkililer + element['ReklamverenYetkililer'][i]['AdSoyad'] + ' ( ' + element['ReklamverenYetkililer'][i]['Eposta'] + ' )';
					}

					element.BasvuranAjansYetkililer = '';
					for (var i = 0; i < element['BasvuranAjansYetkililer'].length; i++) {
						if (i > 0) {
							element.BasvuranAjansYetkililer = element.BasvuranAjansYetkililer + ', ';
						}
						element.BasvuranAjansYetkililer = element.BasvuranAjansYetkililer + element['BasvuranAjansYetkililer'][i]['AdSoyad'] + ' ( ' + element['BasvuranAjansYetkililer'][i]['Eposta'] + ' )';
					}

					element.KatkidaBulunanAjanslarFormated = '';
					for (var i = 0; i < element['KatkidaBulunanAjanslar'].length; i++) {
						if (i > 0) {
							element.KatkidaBulunanAjanslarFormated = element.KatkidaBulunanAjanslarFormated + ', ';
						}
						element.KatkidaBulunanAjanslarFormated = element.KatkidaBulunanAjanslarFormated + element['KatkidaBulunanAjanslar'][i]['Ajans'];
					}

					this.rows.push(element);
					this.temp.push(element);
				});
				// console.log(this.rows);
				// console.log(this.temp);
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	updateIdFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.Id.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	updateMarkaAdiFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.MarkaAdi.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	updateKampanyaAdiFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.KampanyaAdi.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	updateDurumFilter(event) {
		console.log(event.target.value);

		// return;
		const val = event.target.value;
		if (val < -1) {
			this.rows = this.temp;
			// Whenever the filter changes, always go back to the first page
			this.table.offset = 0;
			return;
		}
		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.EBook_Onay == val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	onSort(event) {
		// event was triggered, start sort sequence
		// console.log('Sort Event', event);
		this.loading = true;
		// emulate a server request with a timeout
		// setTimeout(() => {
		const rows = [...this.rows];
		// this is only for demo purposes, normally
		// your server would return the result for
		// you and you would just set the rows prop
		const sort = event.sorts[0];
		// console.log(rows);
		// console.log(sort);
		rows.sort((a, b) => {
			return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
		});

		this.rows = rows;
		this.loading = false;
		// }, 1000);
	}

	OpenExcelSelector(content) {

		this.modalService.open(content, { size: 'lg' }).result.then((result) => {
			// console.log(type);
			// this.closeResult = "Closed with: ${result}";

			// console.log("kpamdı");
			// console.log(result);
		}, (reason) => {
			// this.DosyaAdi = "Choose file";
			// this.closeResult = "Dismissed ${this.getDismissReason(reason)}";
			// console.log("kpamdı2");
			// console.log("Dismissed ${this.getDismissReason(reason)}");
		});
	}

	private getDismissReason(reason: any): string {
		// console.log(reason);

		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return "with: ${reason}";
		}
	}

	ExcelExport() {
		// console.log(this.PageData);
		// console.log(this.Excel)
		this.data = []
		var Heading = [];
		Heading.push("FirebaseId")
		if (this.Excel.MarkaAdi == true) {
			Heading.push("Marka Adı");
		}
		if (this.Excel.KampanyaAdi == true) {
			Heading.push("Kampanya Adı");
		}
		if (this.Excel.Kategori == true) {
			Heading.push("Kategori");
		}
		if (this.Excel.SubKategori == true) {
			Heading.push("Alt Kategori");
		}
		if (this.Excel.ReklamverenAd == true) {
			Heading.push("Reklamveren Adı");
		}
		if (this.Excel.ReklamverenAdres == true) {
			Heading.push("Reklamveren Adres");
		}
		if (this.Excel.ReklamverenYetkililer == true) {
			Heading.push("Reklamveren Yetkililer");
		}
		if (this.Excel.BasvuranAjansAd == true) {
			Heading.push("BaşvuranAnaAjans Adı");
		}
		if (this.Excel.BasvuranAjansAdres == true) {
			Heading.push("BaşvuranAnaAjans Adres");
		}
		if (this.Excel.BasvuranAjansYetkililer == true) {
			Heading.push("BaşvuranAnaAjans Yetkililer");
		}
		if (this.Excel.KatkidaBulunanAjanslar == true) {
			Heading.push("Katkıda Bulunan Ajanslar");
		}
		if (this.Excel.FormuDolduranAd == true) {
			Heading.push("FormuDolduran Adı");
		}
		if (this.Excel.FormuDolduranGorevi == true) {
			Heading.push("FormuDolduran Gorevi");
		}
		if (this.Excel.FormuDolduranTel == true) {
			Heading.push("FormuDolduran Tel");
		}
		if (this.Excel.FormuDolduranEposta == true) {
			Heading.push("FormuDolduran Eposta");
		}
		if (this.Excel.Tarih == true) {
			Heading.push("Tarih");
		}
		if (this.Excel.GondermeTarihi == true) {
			Heading.push("Gönderilme Tarihi");
		}
		if (this.Excel.SayfaSayisi == true) {
			Heading.push("Sayfa Sayısı");
		}
		this.data.push(Heading);

		this.rows.forEach(element => {
			var temp = [];
			temp.push(element["Id"]);
			if (this.Excel.MarkaAdi == true) {
				temp.push(element["MarkaAdi"]);
			}
			if (this.Excel.KampanyaAdi == true) {
				temp.push(element["KampanyaAdi"]);
			}
			if (this.Excel.Kategori == true) {
				temp.push(element["Kategori"]);
			}
			if (this.Excel.SubKategori == true) {
				temp.push(element["SubKategori"]);
			}
			if (this.Excel.ReklamverenAd == true) {
				temp.push(element["ReklamverenAdi"]);
			}
			if (this.Excel.ReklamverenAdres == true) {
				temp.push(element["ReklamverenAdres"]);
			}
			if (this.Excel.ReklamverenYetkililer == true) {
				temp.push(element["ReklamverenYetkililer"]);
			}
			if (this.Excel.BasvuranAjansAd == true) {
				temp.push(element["BasvuranAjansAdi"]);
			}
			if (this.Excel.BasvuranAjansAdres == true) {
				temp.push(element["BasvuranAjansAdres"]);
			}
			if (this.Excel.BasvuranAjansYetkililer == true) {
				temp.push(element["BasvuranAjansYetkililer"]);
			}
			if (this.Excel.KatkidaBulunanAjanslar == true) {
				temp.push(element["KatkidaBulunanAjanslarFormated"]);
			}
			if (this.Excel.FormuDolduranAd == true) {
				temp.push(element["FormuDolduranAdi"]);
			}
			if (this.Excel.FormuDolduranGorevi == true) {
				temp.push(element["FormuDolduranGorevi"]);
			}
			if (this.Excel.FormuDolduranTel == true) {
				temp.push(element["FormuDolduranTelefon"]);
			}
			if (this.Excel.FormuDolduranEposta == true) {
				temp.push(element["FormuDolduranEposta"]);
			}
			if (this.Excel.Tarih == true) {
				temp.push(element["Tarih"].toString());
			}
			if (this.Excel.GondermeTarihi == true) {
				temp.push(element["Gonderme Tarihi"].toString());
			}
			if (this.Excel.SayfaSayisi == true) {
				temp.push(element["Adim9SayfaSayisi"].toString());
			}
			this.data.push(temp);
		});


		// console.log(this.data)
		// return

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, this.fileName);
	}

	TarihFormatla(Tarih) {
		var options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}

}
