import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../providers/globals/globals';
import { Observable } from 'rxjs';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-sorular',
	templateUrl: './sorular.component.html',
	styleUrls: ['./sorular.component.scss']
})
export class SorularComponent implements OnInit {
	modalReference = null;
	SeciliSayfa = 0;
	Sayfalar = [
		{
			Id: 0,
			Baslik: 'Sayfa Seçiniz',
			Sayfa: "Sayfa Seçiniz",
			Surdurulebilir: 0
		},
		{
			Id: 1,
			Baslik: "Hedef",
			Sayfa: "4",
			Surdurulebilir: 0
		},
		{
			Id: 2,
			Baslik: "Hedef Sürdürülebilir",
			Sayfa: "4",
			Surdurulebilir: 1
		},
		{
			Id: 3,
			Baslik: "Fikir",
			Sayfa: "5",
			Surdurulebilir: 0
		},
		{
			Id: 4,
			Baslik: "Fikir Sürdürülebilir",
			Sayfa: "5",
			Surdurulebilir: 1
		},
		{
			Id: 5,
			Baslik: "Uygulama",
			Sayfa: "6",
			Surdurulebilir: 0
		},
		{
			Id: 6,
			Baslik: "Uygulama Sürdürülebilir",
			Sayfa: "6",
			Surdurulebilir: 1
		},
		{
			Id: 7,
			Baslik: "Sonuç",
			Sayfa: "7",
			Surdurulebilir: 0
		},
		{
			Id: 8,
			Baslik: "Sonuç Sürdürülebilir",
			Sayfa: "7",
			Surdurulebilir: 1
		}
	];
	SelectedSayfaData = {
		Id: 0,
		Baslik: 'Sayfa Seçiniz',
		Sayfa: "Sayfa Seçiniz",
		Surdurulebilir: 0
	}
	PageData: any;
	SelectedData = [];
	loading: boolean = false;
	yuklendi: boolean = false;
	Editor: any;
	Editors = [];
	IcerikTuru = "Metin";
	IcerikIndex = 0;

	selectedFile: any;


	@ViewChild(DatatableComponent) table: DatatableComponent;

	constructor(
		private modalService: NgbModal,
		private toastr: ToastrService,
		private globals: Globals,
		private http: HttpClient,
	) {
		this.DataYukle();
	}

	ngOnInit() {

	}

	SayfaDegisti($event) {
		// console.log($event);		

		const EskiTemp = JSON.parse(JSON.stringify(this.SelectedSayfaData));

		if ($event > 0 && this.SelectedData.length) {
			this.PageData = this.PageData.filter(item => !(item.Adim == EskiTemp.Sayfa && item.Surdurulebilir == EskiTemp.Surdurulebilir));
			this.SelectedData.forEach(element => {
				this.PageData.push(element);
			});
			// console.log(this.PageData)
		}

		const temp = this.Sayfalar.filter(function (d) {
			return d.Id == $event;
		})[0];
		this.SelectedSayfaData = temp;
		// console.log(temp.Sayfa)
		this.SelectedData = this.PageData.filter(function (d) {
			return d.Adim == temp.Sayfa && d.Surdurulebilir == temp.Surdurulebilir;
		});
		// console.log(this.SelectedSayfaData);
		// console.log(this.SelectedData)
	}

	DataYukle() {
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/AdminSorular', { params, headers }).subscribe(
			(response) => {
				this.PageData = response;

				this.PageData.forEach(element => {
					console.log(element);
					if (element.Soru.Type == 'Radio') {
						element.Soru.NewRadio = '';
					}
					if (element.Soru.Type == 'CheckBox') {
						element.Soru.NewCheckBox = '';
						element.Soru.NewCheckBoxType = 'CheckBox';
					}
					if (element.Soru.Type == 'MultiCheckBox') {
						element.Soru['CheckBoxes'].forEach(CheckBoxesElement => {
							CheckBoxesElement.NewCheckBox = '';
							CheckBoxesElement.NewCheckBoxType = 'CheckBox';
						});
					}
				});
				// console.log(this.PageData);
				this.yuklendi = true;
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);

	}

	OpenItemEkleme(content, i) {
		this.IcerikIndex = i;
		this.modalReference = this.modalService.open(content, { size: 'lg' });

		this.modalReference.result.then((result) => {
			// this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	ItemEkle() {
		var temp;
		if (this.IcerikTuru == "Metin") {
			temp = {
				Text: "",
				Type: "Metin"
			};
		}
		else if (this.IcerikTuru == "SoruText") {
			temp = {
				Dipnot: "",
				Soru: "",
				Type: "SoruText"
			};
		}
		else if (this.IcerikTuru == "Kaynak") {
			temp = {
				Dipnot: "",
				Soru: "",
				Type: "Kaynak"
			};
		}
		else if (this.IcerikTuru == "Radio") {
			temp = {
				Radios: [
					{
						Name: "Radio 1",
						Value: false
					}
				],
				Dipnot: "",
				Soru: "",
				NewRadio: "",
				Type: "Radio"
			};
		}
		else if (this.IcerikTuru == "CheckBox") {
			temp = {
				CheckBoxes: [
					{
						Name: "CheckBox 1",
						Value: false,
						Type: "CheckBox"
					}
				],
				NewCheckBox: "",
				NewCheckBoxType: "CheckBox",
				Dipnot: "",
				Soru: "",
				Type: "CheckBox"
			};
		}
		else if (this.IcerikTuru == "MultiCheckBox") {
			temp = {
				CheckBoxes: [
					{
						Grup: "Yeni Grup",
						NewCheckBox: "",
						NewCheckBoxType: "CheckBox",
						SubCheckBoxes: [
							{
								Name: "CheckBox 1",
								Value: false,
								Type: "CheckBox"
							}
						]
					}
				],
				Dipnot: "",
				Soru: "",
				Type: "MultiCheckBox"
			};
		}
		// console.log(this.SelectedData)
		let soru = {
			Adim: parseInt(this.SelectedSayfaData.Sayfa),
			Id: 0,
			Siralama: 0,
			Soru: temp,
			Surdurulebilir: this.SelectedSayfaData.Surdurulebilir,
			Yil: 2023
		}
		this.SelectedData.splice(this.IcerikIndex + 1, 0, soru);
		console.log(this.SelectedData)
		this.modalReference.close();
	}

	MainDelete(sayfa, i) {
		if (this.SelectedData.length > 1) {
			this.SelectedData.splice(i, 1);
		}
		else {
			this.toastr.error("En az 1 Soru olmalıdır");
		}
	}
	MainUp(sayfa, i) {
		var temp = this.SelectedData[i];
		this.SelectedData.splice(i, 1);
		this.SelectedData.splice(i - 1, 0, temp);
	}
	MainDown(sayfa, i) {
		var temp = this.SelectedData[i];
		this.SelectedData.splice(i + 2, 0, temp);
		this.SelectedData.splice(i, 1);
	}

	RadioAdd(sayfa, i) {
		if (this.SelectedData[i].Soru["NewRadio"].trim().length > 0) {
			var temp = {};
			temp["Name"] = this.SelectedData[i].Soru["NewRadio"];
			temp["Value"] = false;
			this.SelectedData[i].Soru["Radios"].push(temp);
			temp = null;
			this.SelectedData[i].Soru["NewRadio"] = "";
		}
	}
	RadioDelete(sayfa, i, RadioIndex) {
		if (this.SelectedData[i].Soru["Radios"].length > 1) {
			this.SelectedData[i].Soru["Radios"].splice(RadioIndex, 1);
		}
		else {
			this.toastr.error("En az 1 Radio olmalıdır");
		}
	}
	RadioUp(sayfa, i, RadioIndex) {
		var temp = this.SelectedData[i].Soru["Radios"][RadioIndex];
		this.SelectedData[i].Soru["Radios"].splice(RadioIndex, 1);
		this.SelectedData[i].Soru["Radios"].splice(RadioIndex - 1, 0, temp);
	}
	RadioDown(sayfa, i, RadioIndex) {
		var temp = this.SelectedData[i].Soru["Radios"][RadioIndex];
		this.SelectedData[i].Soru["Radios"].splice(RadioIndex + 2, 0, temp);
		this.SelectedData[i].Soru["Radios"].splice(RadioIndex, 1);
	}

	CheckBoxAdd(sayfa, i) {
		if (this.SelectedData[i].Soru["NewCheckBox"].trim().length > 0) {
			var temp = {};
			temp["Name"] = this.SelectedData[i].Soru["NewCheckBox"];
			temp["Value"] = false;
			temp["Type"] = this.SelectedData[i].Soru["NewCheckBoxType"];
			this.SelectedData[i].Soru["CheckBoxes"].push(temp);
			temp = null;
			this.SelectedData[i].Soru["NewCheckBox"] = "";
			this.SelectedData[i].Soru["NewCheckBoxType"] = "CheckBox";
		}
		// console.log(this.PageData["Adim5"]);
	}
	CheckBoxDelete(sayfa, i, CheckBoxIndex) {
		if (this.SelectedData[i].Soru["CheckBoxes"].length > 1) {
			this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex, 1);
		}
		else {
			this.toastr.error("En az 1 CheckBox olmalıdır");
		}
	}
	CheckBoxUp(sayfa, i, CheckBoxIndex) {
		var temp = this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex];
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex, 1);
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex - 1, 0, temp);
	}
	CheckBoxDown(sayfa, i, CheckBoxIndex) {
		var temp = this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex];
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex + 2, 0, temp);
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex, 1);
	}

	MultiCheckBoxGrupAdd(sayfa, i, CheckBoxIndex) {
		var temp = {
			Grup: "Yeni Grup",
			NewCheckBox: "",
			NewCheckBoxType: "CheckBox",
			SubCheckBoxes: [
				{
					Name: "CheckBox 1",
					Value: false,
					Type: "CheckBox"
				}
			]
		};
		// this.SelectedData[i].Soru["CheckBoxes"].push(temp);
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex + 1, 0, temp);
	}
	MultiCheckBoxGrupDelete(sayfa, i, CheckBoxIndex) {
		if (this.SelectedData[i].Soru["CheckBoxes"].length > 1) {
			this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex, 1);
		}
		else {
			this.toastr.error("En az 1 grup olmalıdır");
		}
	}
	MultiCheckBoxGrupUp(sayfa, i, CheckBoxIndex) {
		var temp = this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex];
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex, 1);
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex - 1, 0, temp);
	}
	MultiCheckBoxGrupDown(sayfa, i, CheckBoxIndex) {
		var temp = this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex];
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex + 2, 0, temp);
		this.SelectedData[i].Soru["CheckBoxes"].splice(CheckBoxIndex, 1);
	}

	MultiCheckBoxAdd(sayfa, i, CheckBoxIndex) {
		if (this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["NewCheckBox"].trim().length > 0) {
			var temp = {};
			temp["Name"] = this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["NewCheckBox"];
			temp["Value"] = false;
			temp["Type"] = this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["NewCheckBoxType"];
			this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"].push(temp);
			temp = null;
			this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["NewCheckBox"] = "";
			this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["NewCheckBoxType"] = "CheckBox";
		}
		// console.log(this.PageData["Adim5"]);
	}
	MultiCheckBoxDelete(sayfa, i, CheckBoxIndex, SubCheckBoxIndex) {
		if (this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"].length > 1) {
			this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"].splice(SubCheckBoxIndex, 1);
		}
		else {
			this.toastr.error("En az 1 CheckBox olmalıdır");
		}
	}
	MultiCheckBoxUp(sayfa, i, CheckBoxIndex, SubCheckBoxIndex) {
		var temp = this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"][SubCheckBoxIndex];
		this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"].splice(SubCheckBoxIndex, 1);
		this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"].splice(SubCheckBoxIndex - 1, 0, temp);
	}
	MultiCheckBoxDown(sayfa, i, CheckBoxIndex, SubCheckBoxIndex) {
		var temp = this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"][SubCheckBoxIndex];
		this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"].splice(SubCheckBoxIndex + 2, 0, temp);
		this.SelectedData[i].Soru["CheckBoxes"][CheckBoxIndex]["SubCheckBoxes"].splice(SubCheckBoxIndex, 1);
	}

	Kaydet() {

		if (this.SelectedData.length) {
			this.PageData = this.PageData.filter(item => !(item.Adim == this.SelectedSayfaData.Sayfa && item.Surdurulebilir == this.SelectedSayfaData.Surdurulebilir));
			this.SelectedData.forEach(element => {
				this.PageData.push(element);
			});
			// console.log(this.PageData)
		}

		let Adim4Sira = 0;
		let Adim4SurdurulebilieSira = 0;
		let Adim5Sira = 0;
		let Adim5SurdurulebilieSira = 0;
		let Adim6Sira = 0;
		let Adim6SurdurulebilieSira = 0;
		let Adim7Sira = 0;
		let Adim7SurdurulebilieSira = 0;

		let temp = JSON.parse(JSON.stringify(this.PageData));
		temp.forEach(element => {
			if (element.Soru.Type == "Radio") {
				delete element.Soru.NewRadio;
			}
			if (element.Soru.Type == "CheckBox") {
				delete element.Soru.NewCheckBox;
				delete element.Soru.NewCheckBoxType;
			}
			if (element.Soru.Type == "MultiCheckBox") {
				element.Soru["CheckBoxes"].forEach(CheckBoxesElement => {
					delete CheckBoxesElement.NewCheckBox;
					delete CheckBoxesElement.NewCheckBoxType;
				});
			}

			if (element.Adim === 4 && element.Surdurulebilir === 0) {
				Adim4Sira++;
				element.Siralama = Adim4Sira;
			}
			else if (element.Adim === 4 && element.Surdurulebilir === 1) {
				Adim4SurdurulebilieSira++;
				element.Siralama = Adim4SurdurulebilieSira;
			}
			else if (element.Adim === 5 && element.Surdurulebilir === 0) {
				Adim5Sira++;
				element.Siralama = Adim5Sira;
			}
			else if (element.Adim === 5 && element.Surdurulebilir === 1) {
				Adim5SurdurulebilieSira++;
				element.Siralama = Adim5SurdurulebilieSira;
			}
			else if (element.Adim === 6 && element.Surdurulebilir === 0) {
				Adim6Sira++;
				element.Siralama = Adim6Sira;
			}
			else if (element.Adim === 6 && element.Surdurulebilir === 1) {
				Adim6SurdurulebilieSira++;
				element.Siralama = Adim6SurdurulebilieSira;
			}
			else if (element.Adim === 7 && element.Surdurulebilir === 0) {
				Adim7Sira++;
				element.Siralama = Adim7Sira;
			}
			else if (element.Adim === 7 && element.Surdurulebilir === 1) {
				Adim7SurdurulebilieSira++;
				element.Siralama = Adim7SurdurulebilieSira;
			}
		});
		// console.log(this.PageData)
		// console.log(temp)
		// console.log(this.SelectedSayfaData.Sayfa.toString())
		// return
		// console.log(this.PageData["Adim3"]);
		// console.log(temp);
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/AdminSorular', temp, { params, headers }).subscribe(
			(response) => {
				this.toastr.success("Kaydedildi.");
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);

		// console.log(this.PageData["Adim3"]);

	}

	FileChange(event: any): void {
		this.selectedFile = event.target.files[0];
		if (this.selectedFile == null) {
			this.toastr.error("Dosya Seçiniz.");
			return;
		}
		// console.log('File Name: ' + this.selectedFile.name);
		// console.log('<br>File Size(byte): ' + this.selectedFile.size);
		// console.log('<br>File Type: ' + this.selectedFile.type);

		// var can = new Blob([this.selectedFile], { type: this.selectedFile.type });
		const uploadData = new FormData();
		uploadData.append('file', this.selectedFile, this.selectedFile.name);

		const params = new HttpParams();
		// var headers = new HttpHeaders()
		// 	.append('Authorization', 'Bearer ' + this.globals.User.Token);
		const headers = new HttpHeaders();
		var url = "https://api.effieturkiye.org/AdminEffieCalismaDosyalari";

		this.http.post(url, uploadData, {
			// reportProgress: true,
			// observe: 'events',
			params: params,
			headers: headers
		})
			.subscribe(data => {
				this.toastr.success("Dosya yüklendi",);
			},
				err => {
					// console.log("Error occured.");
					// console.log(err);
					if (err.status == 401) {

					}
					else {
						this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
					}
				});
	}

}