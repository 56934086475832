import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';;
import swal from 'sweetalert2';
import { Globals } from '../providers/globals/globals';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-kategoriler',
	templateUrl: './kategoriler.component.html',
	styleUrls: ['./kategoriler.component.scss']
})
export class KategorilerComponent implements OnInit {
	Kategoriler: any;
	loading: boolean = false;
	yuklendi: boolean = false;
	KategoriSelected: boolean = false;
	SelectedKategori = -1;

	YeniKategori = {
		Id: 0,
		ParentId: 0,
		Kategori: '',
		Aciklama: '',
		SubKategoriler: []
	};

	SubKategoriSelected: boolean = false;
	SelectedSubKategori = -1;
	YeniSubKategori = {
		Id: 0,
		ParentId: 0,
		Kategori: '',
		Aciklama: '',
		Surdurulebilir: false
	};

	editor1: any;
	editor2: any;

	constructor(
		private toastr: ToastrService,
		private globals: Globals,
		private http: HttpClient,
	) {
		this.DataYukle();
	}

	ngOnInit() {

	}

	EditorCreated1(quill) {
		const toolbar = quill.getModule('toolbar');
		this.editor1 = quill;
	}

	DataYukle() {
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/AdminKategoriler', { params, headers }).subscribe(
			(response) => {
				this.Kategoriler = response;
				this.yuklendi = true;
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	Kaydet() {
		// this.PageData["Adim1"] = this.Kategoriler;
		// console.log(this.Kategoriler);
		// return;
		let ParentSay = 0;
		this.Kategoriler.forEach(element => {
			element.Siralama = ParentSay;
			let say = 1;
			element.SubKategoriler.forEach(SubElement => {
				SubElement.Siralama = say;
				say++;
			});
			ParentSay++;
		});
		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.post('https://api.effieturkiye.org/AdminKategoriler', this.Kategoriler, { params, headers }).subscribe(
			(response) => {
				this.Kategoriler = response;
				this.toastr.success("Kaydedildi.");
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	SelectKategori(index) {
		this.SelectedKategori = index;
		this.KategoriSelected = true;
	}

	KategoriEkle() {
		this.SelectedKategori = -1;
		this.KategoriSelected = true;
	}
	KategoriEkleKaydet() {
		this.Kategoriler.push(this.YeniKategori);
		this.YeniKategori = {
			Id: 0,
			ParentId: 0,
			Kategori: '',
			Aciklama: '',
			SubKategoriler: []
		};
		this.SelectedKategori = this.Kategoriler.length - 1;
		this.KategoriSelected = true;
	}

	KategoriSil(item) {
		// console.log(item)
		swal({
			title: 'Emin misiniz?',
			text: "Bu Kategori ile Alt Kategorileri de silinecek!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0CC27E',
			cancelButtonColor: '#FF586B',
			confirmButtonText: 'Evet, silinsin!',
			cancelButtonText: "Hayır"
		}).then((isConfirm) => {
			// console.log(isConfirm);
			if (isConfirm.value) {
				this.Kategoriler.splice(item, 1);
				//herşeyi temizle
			}
			if (isConfirm.dismiss) {
				// console.log("İptal");
				// swal(
				// 	'Canceled!',
				// 	'',
				// 	'error'
				// );
			}
		}).catch(swal.noop);
	}

	KategoriUp(i) {
		var temp = this.Kategoriler[i];
		this.Kategoriler.splice(i, 1);
		this.Kategoriler.splice(i - 1, 0, temp);
	}

	KategoriDown(i) {
		var temp = this.Kategoriler[i];
		this.Kategoriler.splice(i + 2, 0, temp);
		this.Kategoriler.splice(i, 1);
	}

	SelectSubKategori(index) {
		this.SelectedSubKategori = index;
		this.SubKategoriSelected = true;
		document.getElementById('SubKategoriler').scrollIntoView();
	}

	SubKategoriEkle() {
		this.SelectedSubKategori = -1;
		this.SubKategoriSelected = true;
	}

	SubKategoriEkleKaydet() {
		this.YeniSubKategori.ParentId = this.Kategoriler[this.SelectedKategori].Id;
		this.Kategoriler[this.SelectedKategori].SubKategoriler.push(this.YeniSubKategori);
		this.YeniSubKategori = {
			Id: 0,
			ParentId: 0,
			Kategori: '',
			Aciklama: '',
			Surdurulebilir: false
		};
		this.SelectedSubKategori = this.Kategoriler[this.SelectedKategori].SubKategoriler.length - 1;
		this.KategoriSelected = true;
		this.SubKategoriSelected = true;
	}

	SubKategoriSil(item) {
		// console.log(item)
		// console.log(this.Kategoriler[this.SelectedKategori].SubKategoriler[item].Id);
		// return;
		swal({
			title: 'Emin misiniz?',
			text: "Bu Alt Kategori silinecek!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0CC27E',
			cancelButtonColor: '#FF586B',
			confirmButtonText: 'Evet, silinsin!',
			cancelButtonText: "Hayır"
		}).then((isConfirm) => {
			// console.log(isConfirm);
			if (isConfirm.value) {
				// this.Kategoriler[this.SelectedKategori].SubKategoriler.splice(item, 1);
				const params = new HttpParams();
				let headers = new HttpHeaders();
				headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
				headers = headers.append('Content-Type', 'application/json');
				headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

				const data = {
					Id: this.Kategoriler[this.SelectedKategori].SubKategoriler[item].Id
				}

				this.http.post('https://api.effieturkiye.org/AdminKategorilerSil', data, { params, headers }).subscribe(
					(response) => {
						this.Kategoriler = response;
						this.toastr.success("Kaydedildi.");
					},
					error => {
						console.log('Error', error);
						if (error.status === 401) {
							// localStorage.clear();
							// this.globals.Sifirla();
							// this.router.navigateByUrl('/home');
							// this.messageService.sendMessage('logout');
						}
						this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
					}
				);
			}
			if (isConfirm.dismiss) {
				// console.log("İptal");
				// swal(
				// 	'Canceled!',
				// 	'',
				// 	'error'
				// );
			}
		}).catch(swal.noop);
	}

	SubKategoriUp(i) {
		var temp = this.Kategoriler[this.SelectedKategori].SubKategoriler[i];
		this.Kategoriler[this.SelectedKategori].SubKategoriler.splice(i, 1);
		this.Kategoriler[this.SelectedKategori].SubKategoriler.splice(i - 1, 0, temp);
	}

	SubKategoriDown(i) {
		var temp = this.Kategoriler[this.SelectedKategori].SubKategoriler[i];
		this.Kategoriler[this.SelectedKategori].SubKategoriler.splice(i + 2, 0, temp);
		this.Kategoriler[this.SelectedKategori].SubKategoriler.splice(i, 1);
	}
}
