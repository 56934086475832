import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHandler, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Globals } from '../providers/globals/globals';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
	selector: 'app-kullanicilistesi',
	templateUrl: './kullanicilistesi.component.html',
	styleUrls: ['./kullanicilistesi.component.scss']
})
export class KullanicilistesiComponent implements OnInit {
	rows = [];
	temp = [];
	loading: boolean = false;
	data: AOA = [];
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: string = 'Kullanicilar.xlsx';
	Excel = {
		AdSoyad: true,
		Eposta: true,
		FirmaAdi: true,
		FaturaEmail: true,
		FaturaUnvani: true,
		VergiDairesi: true,
		VergiNumarasi: true,
		Gorevi: true,
		Telefon: true,
		CreatedAt: true,
		MailApproved: true,
		IsAdmin: true


	};

	@ViewChild(DatatableComponent) table: DatatableComponent;

	constructor(
		public globals: Globals,
		private modalService: NgbModal,
		private toastr: ToastrService,
		private http: HttpClient,
	) {
		// this.temp = [...data];
		// this.rows = data;
		this.DataYukle();
	}

	ngOnInit() {

	}

	DataYukle() {

		const params = new HttpParams();
		let headers = new HttpHeaders();
		headers = headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Bearer ' + this.globals.User.Token);

		this.http.get('https://api.effieturkiye.org/AdminKullanicilar', { params, headers }).subscribe(
			(response) => {
				// console.log(response);
				let TempJson = JSON.parse(JSON.stringify(response));
				TempJson.forEach(element => {
					element['Id'] = element['Id'].toString();

					this.rows.push(element);
					this.temp.push(element);
				});
				// console.log(this.rows);
				// console.log(this.temp);
			},
			error => {
				console.log('Error', error);
				if (error.status === 401) {
					// localStorage.clear();
					// this.globals.Sifirla();
					// this.router.navigateByUrl('/home');
					// this.messageService.sendMessage('logout');
				}
				this.toastr.error('Bir hata oluştu, Lütfen tekrar deneyiniz');
			}
		);
	}

	updateAdSoyadFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.AdSoyad.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	updateFirmaAdiFilter(event) {
		const val = event.target.value.toLowerCase();

		// filter our data
		const temp = this.temp.filter(function (d) {
			return d.FirmaAdi.toLowerCase().indexOf(val) !== -1 || !val;
		});

		// update the rows
		this.rows = temp;
		// Whenever the filter changes, always go back to the first page
		this.table.offset = 0;
	}

	onSort(event) {
		// event was triggered, start sort sequence
		// console.log('Sort Event', event);
		this.loading = true;
		// emulate a server request with a timeout
		// setTimeout(() => {
		const rows = [...this.rows];
		// this is only for demo purposes, normally
		// your server would return the result for
		// you and you would just set the rows prop
		const sort = event.sorts[0];
		// console.log(rows);
		// console.log(sort);
		rows.sort((a, b) => {
			return a[sort.prop].localeCompare(b[sort.prop]) * (sort.dir === 'desc' ? -1 : 1);
		});

		this.rows = rows;
		this.loading = false;
		// }, 1000);
	}

	OpenExcelSelector(content) {
		this.modalService.open(content, { size: 'lg' }).result.then((result) => {
			// console.log(type);
			// this.closeResult = "Closed with: ${result}";

			// console.log("kpamdı");
			// console.log(result);
		}, (reason) => {
			// this.DosyaAdi = "Choose file";
			// this.closeResult = "Dismissed ${this.getDismissReason(reason)}";
			// console.log("kpamdı2");
			console.log("Dismissed ${this.getDismissReason(reason)}");
		});
	}

	private getDismissReason(reason: any): string {
		// console.log(reason);
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return "with: ${reason}";
		}
	}

	ExcelExport() {
		// console.log(this.PageData);
		var Heading = [];
		if (this.Excel.AdSoyad == true) {
			Heading.push("Ad Soyad");
		}
		if (this.Excel.Eposta == true) {
			Heading.push("E-Posta");
		}
		if (this.Excel.FirmaAdi == true) {
			Heading.push("Firma Adı");
		}
		if (this.Excel.FaturaEmail == true) {
			Heading.push("Fatura Email");
		}
		if (this.Excel.FaturaUnvani == true) {
			Heading.push("Fatura Unvani");
		}
		if (this.Excel.VergiDairesi == true) {
			Heading.push("VergiDairesi");
		}
		if (this.Excel.VergiNumarasi == true) {
			Heading.push("VergiNumarasi");
		}
		if (this.Excel.Gorevi == true) {
			Heading.push("Görevi");
		}
		if (this.Excel.Telefon == true) {
			Heading.push("Telefon");
		}
		if (this.Excel.CreatedAt == true) {
			Heading.push("Üyelik Tarihi");
		}
		if (this.Excel.MailApproved == true) {
			Heading.push("Mail Onay");
		}
		if (this.Excel.IsAdmin == true) {
			Heading.push("IsAdmin");
		}
		this.data.push(Heading);


		this.rows.forEach(element => {
			var temp = [];
			if (this.Excel.AdSoyad == true) {
				temp.push(element["AdSoyad"]);
			}
			if (this.Excel.Eposta == true) {
				temp.push(element["Email"]);
			}
			if (this.Excel.FirmaAdi == true) {
				temp.push(element["FirmaAdi"]);
			}
			if (this.Excel.FaturaEmail == true) {
				temp.push(element["FaturaEmail"]);
			}
			if (this.Excel.FaturaUnvani == true) {
				temp.push(element["FaturaUnvani"]);
			}
			if (this.Excel.VergiDairesi == true) {
				temp.push(element["VergiDairesi"]);
			}
			if (this.Excel.VergiNumarasi == true) {
				temp.push(element["VergiNumarasi"]);
			}
			if (this.Excel.Gorevi == true) {
				temp.push(element["Gorev"]);
			}
			if (this.Excel.Telefon == true) {
				temp.push(element["Telefon"]);
			}
			if (this.Excel.CreatedAt == true) {
				temp.push(element["RegisterTarih"]);
			}
			if (this.Excel.MailApproved == true) {
				if (element["MailApproved"] == true) {
					temp.push("Evet");
				}
				else {
					temp.push("Hayır");
				}
			}
			if (this.Excel.IsAdmin == true) {
				if (element["IsAdmin"] == true) {
					temp.push("Evet");
				}
				else {
					temp.push("Hayır");
				}
			}
			this.data.push(temp);
		});

		// console.log(this.data)
		// return

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, this.fileName);
	}

	TarihFormatla(Tarih) {
		var options = {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			seconds: 'numeric'
		};
		return Tarih.toLocaleDateString("tr-TR", options);
	}

}
