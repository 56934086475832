import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

    // {
    //     path: '/dashboard', title: 'Yıl Seçimi', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    // },
    {
        path: '/kullanicilar/liste', title: 'Başvuranlar', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    // {
    //     path: '/juriler/liste', title: 'Juriler', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false,submenu: []
    // },
    {
        path: '/tamamlananprojeler/liste/2025', title: '25 Tamamlanan P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/tamamlanmamisprojeler/liste/2025', title: '25 Tamamlanmamış P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/editorgiden/liste/2025', title: '25 Editör Giden', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/silinenprojeler/liste/2025', title: '25 Silinen P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/kategoriler', title: '25 Kategoriler', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/sayfalar', title: '25 Sayfalar', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/sorular', title: '25 Sorular', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/tamamlananprojeler/liste/2024', title: '24 Tamamlanan P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/tamamlanmamisprojeler/liste/2024', title: '24 Tamamlanmamış P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },    
    {
        path: '/editorgiden/liste/2024', title: '24 Editör Giden', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/silinenprojeler/liste/2024', title: '24 Silinen P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/tamamlananprojeler/liste/2023', title: '23 Tamamlanan P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/tamamlanmamisprojeler/liste/2023', title: '23 Tamamlanmamış P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/editorgiden/liste/2023', title: '23 Editör Giden', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/silinenprojeler/liste/2023', title: '23 Silinen P.', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    },
    {
        path: '/logout', title: 'Çıkış', icon: 't', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
];
