import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../providers/globals/globals';

@Component({
	selector: 'app-juriekle',
	templateUrl: './juriekle.component.html',
	styleUrls: ['./juriekle.component.scss']
})
export class JuriEkleComponent implements OnInit {

	PageData: any = {
		AdSoyad: "",
		Eposta: "",
		Telefon: "",
		Sifre: ""
	};
	
	constructor(
		public globals: Globals,
		private router: Router, 
		private toastr: ToastrService,
	) {

	}

	ngOnInit() {
		
	}

	Kaydet()
	{
		// console.log(this.PageData);
		// return;
		if (this.PageData["AdSoyad"].length == 0 || this.PageData["AdSoyad"] == "") {
			this.toastr. error("Ad Soyad boş olamaz.");
			return;
		}
		let that = this;
		// this.db.collection("Juriler").add(this.PageData)
		// .then(function(docRef) {
		// 	console.log("Document written with ID: ", docRef.id);
		// 	that.router.navigateByUrl('/juriler');
		// })
		// .catch(function(error) {
		// 	console.error("Error adding document: ", error);
		// });
	}
}
