import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-idle',
    templateUrl: './idle.component.html',
    styleUrls: ['./idle.component.scss']
})
export class IdleComponent implements OnInit {

    constructor(
        
    ) {

    }


    ngOnInit() {

    }

    
}