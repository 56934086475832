// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAwf-J8FKbuTZH-F-PJ8-v_uzJK5D9QdsI",
    authDomain: "effieturkiye-8da22.firebaseapp.com",
    databaseURL: "https://effieturkiye-8da22.firebaseio.com",
    projectId: "effieturkiye-8da22",
    storageBucket: "effieturkiye-8da22.appspot.com",
    messagingSenderId: "667004425927",
    appId: "1:667004425927:web:b3da8a86e9abd9cdeed0e2"
  }
};