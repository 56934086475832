import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
	// segment: string = '26aralik';
	User = {
		Token: '',
		Decoded: {
			Basvuru: 1,
			IsAdmin: 0,
			IsEditor: 0,
			UserId: 0,
			iss: "effieturkiye.org",
			AdSoyad: ''
		}
	};
	Yonetim = [];
}