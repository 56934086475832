import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
	selector: 'app-juriedit',
	templateUrl: './juriedit.component.html',
	styleUrls: ['./juriedit.component.scss']
})
export class JuriEditComponent implements OnInit {
	yuklendi = false;
	JuriId = "";
	PageData: Object = [];
	data: AOA = [];
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: string = 'Kullanicilar.xlsx';
	Excel = {
		AdSoyad: true,
		Eposta: true,
		Telefon: true,
		Sifre: true,
	};

	constructor(
		private toastr: ToastrService, 
		private router: Router, 
		private activatedRoute: ActivatedRoute,
		private modalService: NgbModal,
	) 
	{
		this.activatedRoute.params
			.subscribe(params => {
				// console.log(params.JuriId);
				this.JuriId = params.JuriId.toString();
					this.fileName = this.JuriId + ".xlsx";
					this.DataYukle();
			});
	}

	ngOnInit() {
		
	}


	DataYukle() {
		// console.log(this.JuriId)
		// let that = this;
		// this.db.collection("Juriler").doc(this.JuriId).ref.get().then(function (doc) {
		// 	if (doc.exists) {
		// 		that.PageData = doc.data();
		// 		that.yuklendi = true;
		// 	} else {
		// 		// console.log("No such document!");
		// 		that.toastr.typeError("Bir hata oluştu, Lütfen tekrar deneyiniz");
		// 	}
		// }).catch(function (error) {
		// 	// console.log("Error getting document:", error);
		// 	that.toastr.typeError("Bir hata oluştu, Lütfen tekrar deneyiniz");
		// });
	}

	Kaydet() {
		if (this.PageData["AdSoyad"].length == 0 || this.PageData["AdSoyad"] == "") {
			this.toastr. error("Ad Soyad boş olamaz.");
			return;
		}
		if (this.PageData["Eposta"].length == 0 || this.PageData["Eposta"] == "") {
			this.toastr. error("EPosta boş olamaz.");
			return;
		}

		// let that = this;
		// this.db.collection("Juriler").doc(this.JuriId).set(this.PageData).then(function () {
		// 	that.toastr.typeSuccess("Kaydedildi.");
		// })
		// 	.catch(function (error) {
		// 		that.toastr.typeError("Bir hata oluştu, Lütfen tekrar deneyiniz");
		// 	});
	}

	JuriSil() {
		// console.log(index);
		swal({
			title: 'Eminmisiniz?',
			text: "Bu Juriyi Silmek Üzeresiniz!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0CC27E',
			cancelButtonColor: '#FF586B',
			confirmButtonText: 'Evet SİL !!!',
			cancelButtonText: "Hayır, Silme"
		}).then((isConfirm) => {
			// console.log(isConfirm);
			if (isConfirm.value) {
				let that = this;
				// this.db.collection("Juriler").doc(this.JuriId).ref.delete().then(function() {
				// 	that.toastr.typeSuccess("Jüri silindi.");
				// 	that.router.navigateByUrl('/juriler');
				// }).catch(function(error) {
				// 	// console.error("Error removing document: ", error);
				// 	that.toastr.typeError("Bir hata oluştu, Lütfen tekrar deneyiniz");
				// });
			}
			if (isConfirm.dismiss) {
				swal(
					'İptal edildi!',
					'Juri silinmemiştir!!',
					'error'
				);
			}
		}).catch(swal.noop);
	}

	OpenExcelSelector(content) {
		 
		this.modalService.open(content, { size: 'lg' }).result.then((result) => {
			// console.log(type);
			// this.closeResult = "Closed with: ${result}";
			
			// console.log("kpamdı");
			// console.log(result);
		}, (reason) => {
			// this.DosyaAdi = "Choose file";
			// this.closeResult = "Dismissed ${this.getDismissReason(reason)}";
			// console.log("kpamdı2");
			// console.log("Dismissed ${this.getDismissReason(reason)}");
		});
	}

	private getDismissReason(reason: any): string {
		// console.log(reason);
		 
		if (reason === ModalDismissReasons.ESC) {
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return "by clicking on a backdrop";
		} else {
			return "with: ${reason}";
		}
	}

	ExcelExport() {
		// console.log(this.PageData);
		var Heading = [];
		if (this.Excel.AdSoyad == true) {
			Heading.push("Ad Soyad");
		}
		if (this.Excel.Eposta == true) {
			Heading.push("E-Posta");
		}
		if (this.Excel.Telefon == true) {
			Heading.push("Telefon");
		}
		if(this.Excel.Sifre == true)
		{
			Heading.push("Şifre");
		}
		this.data.push(Heading);



		var temp = [];
		if (this.Excel.AdSoyad == true) {
			temp.push(this.PageData["AdSoyad"]);
		}
		if (this.Excel.Eposta == true) {
			temp.push(this.PageData["Eposta"]);
		}
		if (this.Excel.Telefon == true) {
			temp.push(this.PageData["Telefon"]);
		}
		if (this.Excel.Sifre == true) {
			temp.push(this.PageData["Sifre"]);
		}
		this.data.push(temp);


		// console.log(this.data)
		// return

		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
		XLSX.writeFile(wb, this.fileName);
	}
}
