import { Component, ViewContainerRef, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageServiceService } from './services/message-service.service';
import { Globals } from './providers/globals/globals';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import jwt_decode from "jwt-decode";
import { Location } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    loggedin: boolean = false;
    subscription: Subscription;
    message: any;
    constructor(
        vRef: ViewContainerRef,
        private messageService: MessageServiceService,
        private globals: Globals,
        private router: Router,
        private zone: NgZone,
        private cookieService: CookieService,
        private location: Location,
    ) {
        if (this.cookieService.check("Token")) {
			this.globals.User.Token = this.cookieService.get("Token");
			this.globals.User.Decoded = jwt_decode(this.globals.User.Token);
			// console.log(this.globals.User.Decoded);
            if(this.globals.User.Decoded.IsAdmin === 1)
            {
                // this.router.navigateByUrl('/dashboard');
                if (this.router.url === '/' && this.location.path() === '') {
                    this.router.navigateByUrl('/dashboard');
                }
                if (this.router.url === '/' && this.location.path() !== '' && this.location.path() !== '/dashboard') {
                    // this.router.navigateByUrl('/dashboard');
                    this.router.navigateByUrl(this.location.path());
                }
            }
            else
            {
                window.location.href = 'https://uyelik.effieturkiye.org/home/basvuruyonetim';
            }
		}
		else
		{
			window.location.href = 'https://uyelik.effieturkiye.org/home/basvuruyonetim';
		}
        
        this.subscription = this.messageService.getMessage().subscribe(message => {
            this.message = message;
            // console.log(message);

            if (this.message.text == "logout") {
                this.loggedin = false;
                this.globals.User = {
                    Token: '',
                    Decoded: {
                        Basvuru: 1,
                        IsAdmin: 0,
                        IsEditor: 0,
                        UserId: 0,
                        iss: "effieturkiye.org",
                        AdSoyad: ''
                    }
                };
                this.globals.Yonetim = null;
                // this.router.navigateByUrl('/');
                window.location.href = 'https://uyelik.effieturkiye.org/home/basvuruyonetim';

            }
        });


    }

    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        this.subscription.unsubscribe();
    }

    logout() {
        // console.log("çıkış");
        this.messageService.sendMessage("logout");
    }
}